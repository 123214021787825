<template>
  <v-card class="elevation-0">
    <v-container fluid>
      <v-form ref="tierSetupForm" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" class="mr-3">
            <v-chip-group v-model="selectedTier">
              <v-chip
                v-for="(tier, index) in tiers"
                :key="`tier_${index}`"
                rounded
                small
                :class="tierChipClass(index)"
              >
                <span v-if="index == 0"> {{ $t('details.baseWheel') }}</span>
                <span v-else>Tier {{ index + 1 }}</span>
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="mb-6"></v-divider>
        </v-row>

        <v-row>
          <v-col cols="5">
            <p class="mb-2 text-subtitle-2">
              {{ $t("details.segment_options") }}
            </p>
            <v-chip-group
              v-model="segmentChoice"
              class="mb-2"
              data-test="seg_num-chips"
            >
              <v-chip
                v-for="option in segment_options"
                :key="`segment_option_${option}`"
                :value="option"
                :rules="(v) => !!v || 'No of segments is required'"
                :input-value="segmentChoice == option"
                :class="
                  option == segmentChoice
                    ? `white--text seg_${segmentChoice}`
                    : 'black--text outlined white'
                "
                mandatory
                @change="triggerSegmentUpdateConfirmation(option)"
              >
                {{ option }}
              </v-chip>
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="mb-6"></v-divider>
        </v-row>
      </v-form>

      <v-form ref="segmentForm">
        <v-row>
          <v-col cols="5" :lg="4" 
            @dragover="handleForegroundDragOver"
            @drop="handleForegroundDrop">
            <p class="mb-1 text-subtitle-2">{{ $t("details.upload_foreground_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_foreground_content_subtitle') }}</span>
              </v-tooltip>
            </p>            

            <v-file-input
              v-model="foregroundFiles"
              label="Drag and drop, or browse your files"
              accept="image/*, .mp4, .webm"
              counter
              multiple
              :disabled="disableField"
              outlined
              :show-size="true"
              prepend-icon=""
              class="file_upload"
              @change="onForegroundFilesUploaded"
            >
              <template v-slot:selection="{ text }">
                <v-chip dark label small class="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="5" :lg="4" 
            @dragover="handleBackgroundDragOver"
            @drop="handleBackgroundDrop">
            <p class="mb-1 text-subtitle-2">{{ $t("details.upload_background_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_background_content_subtitle') }}</span>
              </v-tooltip>
            </p>

            <v-file-input
              v-model="backgroundFiles"
              label="Drag and drop, or browse your files"
              accept="image/*, .mp4, .webm"
              counter
              multiple
              :disabled="disableField"
              outlined
              :show-size="true"
              prepend-icon=""
              class="file_upload"
              @change="onBackgroundFilesUploaded"
            >
              <template v-slot:selection="{ text }">
                <v-chip dark label small class="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="4">
            <v-progress-linear
              v-if="foregroundUploading"
              :value="foregroundUploadProgress"
              color="primary"
              height="5"
              class="ma-2"
            ></v-progress-linear>
            <p
              v-if="foregroundUploading"
              class="ml-3 text-subtitle-2 font-weight-light"
            >
              Uploading {{ foregroundUploadedCount }} of {{ foregroundFiles.length }}
            </p>
            <v-progress-linear
              v-if="backgroundUploading"
              :value="backgroundUploadProgress"
              color="primary"
              height="5"
              class="ma-2"
            ></v-progress-linear>
            <p
              v-if="backgroundUploading"
              class="ml-3 text-subtitle-2 font-weight-light"
            >
              Uploading {{ backgroundUploadedCount }} of {{ backgroundFiles.length }}
            </p>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">
              {{ $t("details.select_foreground_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_foreground_content_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-select
              v-model="foregroundContent"
              :items="foregroundOptions"
              :disabled="disableField"
              label="Make a selection"
              :item-text="assetNameFormatted"
              item-value="displayName"
              return-object
              dense
              flat
              outlined
              solo
              clearable
              searchable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchForegroundTerm" placeholder="Search" @input="searchForeground" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">{{ $t("details.select_foreground_content_size")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_foreground_content_size_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="foregroundContentSize"
              placeholder="Enter a value"
              :rules="[numberBetweenOneAndHundred]"
              :disabled="disableField"
              dense
              required
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">{{ $t("details.select_background_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_background_content_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-select
              v-model="backgroundContent"
              :items="backgroundOptions"
              :disabled="disableField"
              label="Make a selection"
              :item-text="assetNameFormatted"
              return-object
              dense
              flat
              outlined
              solo
              clearable
              searchable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchBackgroundTerm" placeholder="Search" @input="searchBackground" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">{{ $t("details.select_background_content_size")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_foreground_content_size_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="backgroundContentSize"
              placeholder="Enter a value"
              :rules="[numberBetweenOneAndHundred]"
              :disabled="disableField"
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">
              {{ $t("details.select_wheelOverlay_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_wheelOverlay_content_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-select
              v-model="wheelOverlayContent"
              :items="wheelOverlayOptions"
              :disabled="disableField"
              label="Make a selection"
              :item-text="assetNameFormatted"
              item-value="displayName"
              return-object
              dense
              flat
              outlined
              solo
              clearable
              searchable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchWheelOverlayTerm" placeholder="Search" @input="searchWheelOverlay" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">{{ $t("details.select_wheelOverlay_content_size")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_wheelOverlay_content_size_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="wheelOverlayContentSize"
              placeholder="Enter a value"
              :rules="[numberBetweenOneAndHundred]"
              :disabled="disableField"
              dense
              required
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">{{ $t("details.select_backgroundOverlay_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_backgroundOverlay_content_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-select
              v-model="backgroundOverlayContent"
              :items="backgroundOverlayOptions"
              :disabled="disableField"
              label="Make a selection"
              :item-text="assetNameFormatted"
              return-object
              dense
              flat
              outlined
              solo
              clearable
              searchable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchBackgroundOverlayTerm" placeholder="Search" @input="searchBackgroundOverlay" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="3">
            <p class="mb-2 text-subtitle-2">{{ $t("details.select_backgroundOverlay_content_size")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.select_foreground_content_size_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="backgroundOverlayContentSize"
              placeholder="Enter a value"
              :rules="[numberBetweenOneAndHundred]"
              :disabled="disableField"
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="mb-6"></v-divider>
        </v-row>

        <v-row>
          <v-col cols="5" :lg="4" 
            @dragover="handleSegmentContentDragOver"
            @drop="handleSegmentContentDrop">
            <p class="mb-1 text-subtitle-2">{{ $t("details.upload_segment_content")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_segment_subtitle') }}</span>
              </v-tooltip>
            </p>

            <v-file-input
              v-model="segmentFiles"
              label="Drag and drop, or browse your files"
              accept="image/*"
              counter
              :disabled="disableField"
              multiple
              outlined
              :show-size="true"
              prepend-icon=""
              class="file_upload"
              @change="onSegmentContentUploaded"
            >
              <template v-slot:selection="{ text }">
                <v-chip dark label small class="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="5" :lg="4"
            @dragover="handleSegmentAnimationDragOver"
            @drop="handleSegmentAnimationDrop">
            <p class="mb-1 text-subtitle-2">{{ $t("details.upload_segment_animations")}}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_segment_animations_subtitle') }}</span>
              </v-tooltip>
            </p>

            <v-file-input
              v-model="segmentAnimationFiles"
              label="Drag and drop, or browse your files"
              accept=".mp4, .webm"
              counter
              :disabled="disableField"
              multiple
              outlined
              :show-size="true"
              prepend-icon=""
              class="file_upload"
              @change="onSegmentAnimationsUploaded"
            >
              <template v-slot:selection="{ text }">
                <v-chip dark label small class="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="4">
            <v-progress-linear
              v-if="segmentUploading"
              :value="segmentUploadProgress"
              color="primary"
              height="5"
              class="ma-2"
            ></v-progress-linear>
            <p
              v-if="segmentUploading"
              class="ml-3 text-subtitle-2 font-weight-light"
            >
              Uploading {{ segmentUploadedCount }} of {{ segmentFiles.length }}
            </p>
            <v-progress-linear
              v-if="segmentAnimationUploading"
              :value="segmentAnimationUploadProgress"
              color="primary"
              height="5"
              class="ma-2"
            ></v-progress-linear>
            <p
              v-if="segmentAnimationUploading"
              class="ml-3 text-subtitle-2 font-weight-light"
            >
              Uploading {{ segmentAnimationUploadedCount }} of {{ segmentAnimationFiles.length }}
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="4" :lg="3">
            <p class="mb-0 text-subtitle-2">{{ $t('details.segment_animation_size') }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.segment_animation_size_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="winningAnimationSize"
              label="Enter a value"
              :rules="[numberBetweenOneAndHundred]"
              :disabled="disableField"
              item-text="name"
              style="max-height: 30%;"
              return-object
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4" :lg="3">
            <p class="mb-0 text-subtitle-2">{{ $t('details.segment_animation_duration') }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.segment_animation_duration_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="winningAnimationDuration"
              label="Enter a value"
              :rules="[numberGreaterThanZero]"
              :disabled="disableField"
              item-text="name"
              return-object
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="4" :lg="3">
            <p class="mb-0 text-subtitle-2">{{ $t('details.segment_animation_delay') }}
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.segment_animation_delay_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="winningAnimationDelay"
              label="Enter a value"
              :rules="[numberGreaterThanZero]"
              :disabled="disableField"
              item-text="name"
              return-object
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-divider></v-divider>
        </v-row>

        <v-row>
          <v-col cols="12" class="mr-3">
            <v-chip-group
              v-model="selectedSegmentDefinition"
              :disabled="disableField"
            >
              <v-chip
                v-for="(seg, index) in segmentDefinitions"
                :key="`segment_definition_${index}`"
                :disabled="disableField"
                rounded
                small
                mandatory
                active-class="primary"
                @change="selectSegmentDefinition(index)"
                :value="index"
              >
                {{ seg.name ? seg.name : index + 1 }}
              </v-chip>
              <v-btn
                elevation="0"
                small
                rounded
                class="mr-2 red white--text float-right"
                @click="deleteSegmentDialog = true"
                :disabled="disableField"
                data-test="add_seg-btn"
                ><v-icon>mdi-minus</v-icon></v-btn
              >
              <v-btn
                elevation="0"
                small
                rounded
                class="black white--text float-right"
                @click="addSegment"
                :disabled="disableField"
                data-test="add_seg-btn"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </v-chip-group>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="mb-4"></v-divider>
        </v-row>

        <v-row>
          <v-col cols="4" :lg="2">
            <p class="mb-2 text-subtitle-2"><span>Segment Value</span>
              <v-tooltip top content-class="segment_value_tooltip_width">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.segment_value_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="segmentName"
              placeholder="Segment name or value"
              :rules="segmentNameRules"
              :disabled="disableField"
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="4" :lg="2">
            <p class="mb-2 text-subtitle-2">
              Segment Text
            </p>
            <v-text-field
              v-model="segmentText"
              placeholder="Segment Text"
              :rules="segmentTextRules"
              :disabled="disableField"
              dense
              solo
              flat
              outlined
            ></v-text-field>
          </v-col>


          <v-col cols="4" :lg="3">
            <p class="mb-2 text-subtitle-2">
              Segment Image
            </p>
            <v-select
              v-model="segmentImage"
              :items="segmentImageOptions"
              label="Make a selection"
              :disabled="disableField"
              :item-text="assetNameFormatted"
              return-object
              dense
              solo
              flat
              outlined
              @change="selectSegmentImage"
              clearable
              searchable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchSegmentImageTerm" placeholder="Search" @input="searchSegmentImage" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="4" :lg="3">
            <p class="mb-2 text-subtitle-2">
              Winning Animation
            </p>
            <v-select
              v-model="segmentAnimation"
              :items="segmentAnimationOptions"
              label="Make a selection"
              :disabled="disableField"
              :item-text="assetNameFormatted"
              return-object
              dense
              flat
              solo
              outlined
              @change="selectSegmentAnimation"
              clearable
              searchable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchSegmentAnimationTerm" placeholder="Search" @input="searchSegmentAnimation" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="4" :lg="2">
            <p class="mb-2 text-subtitle-2">
              Bonus Segment
            </p>
            <v-btn :style="disableBonusSwitch" id="yesBonusButton" dense @click="setBonusSegment(true)" :class="yesBonusButtonClass" class="px-6 text-none rounded-lg">
                Yes
            </v-btn>
            <v-btn :style="disableBonusSwitch" id="noBonusButton" dense @click="setBonusSegment(false)" :class="noBonusButtonClass" class="px-6 text-none rounded-lg">
                No
            </v-btn>
          </v-col>
          
        </v-row>

        <v-row>
          <v-divider class="mb-4"></v-divider>
        </v-row>
      </v-form>

      <!-- Confirmation Dialogs -->
      <v-dialog v-model="warningDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text> Are you sure you want to proceed? </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="updateSegmentCount">Yes</v-btn>
            <v-btn color="red" text @click="cancelSegmentUpdate">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteSegmentDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline">Delete Segment?</v-card-title>
          <v-card-text>  This will remove all instances of the segment in the build wheel step. </v-card-text>
          <v-card-actions>
            <v-btn color="primary" text @click="deleteSelectedSegmentDefinition">Yes</v-btn>
            <v-btn color="red" text @click="deleteSegmentDialog = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  data: () => ({
    valid: false,

    fileInputAttrs: {
        multiple: true, // Allow multiple file selection
      },
    segment_options: [54, 27, 18, 9, 3],
    warningDialog: false,
    segmentToUpdate: null,

    selectedSegmentDefinition: 0,

    fileRules: [
      (value) =>
        !value || value.size < 10000000 || "File size should not exceed 10 MB!", // TODO - specify correct size limit
    ],
    segmentNameRules: [
        v => !!v || 'Name is required',
        v => /^[A-Za-z0-9\s_\-@#!.'"\$€£¥]*$/.test(v) || "Segment name invalid",
    ],
    segmentTextRules: [
        v => /^[A-Za-z0-9\s_\-@#!.'"\$€£¥]*$/.test(v) || "Segment text invalid",
    ],

    foregroundFiles: [],
    foregroundUploading: false,
    foregroundUploadProgress: 0,
    foregroundUploadedCount: 0,
    backgroundFiles: [],
    backgroundUploading: false,
    backgroundUploadProgress: 0,
    backgroundUploadedCount: 0,

    segmentFiles: [],
    segmentAnimationFiles: [],
    segmentItems: [],
    segmentUploading: false,
    segmentUploadProgress: 0,
    segmentUploadedCount: 0,
    segmentAnimationUploading: false,
    segmentAnimationUploadProgress: 0,
    segmentAnimationUploadedCount: 0,
    segmentBonusCooldown: false,

    searchForegroundTerm: '',
    foregroundOptions: [],
    foregroundOptionsCopy: [],
    searchBackgroundTerm: '',
    backgroundOptions: [],
    backgroundOptionsCopy: [],
    
    searchWheelOverlayTerm: '',
    wheelOverlayOptions: [],
    wheelOverlayOptionsCopy: [],
    searchBackgroundOverlayTerm: '',
    backgroundOverlayOptions: [],
    backgroundOverlayOptionsCopy: [],

    searchSegmentImageTerm: '',
    segmentImageOptions: [],
    segmentImageOptionsCopy: [],
    searchSegmentAnimationTerm: '',
    segmentAnimationOptions: [],
    segmentAnimationOptionsCopy: [],
    deleteSegmentDialog: false

  }),
  async created() {
    
  },
  mounted(){
    this.selectedTier = 0;
    this.foregroundOptions = [...this.getForegroundOptions].reverse();
    this.foregroundOptionsCopy = this.foregroundOptions;

    this.backgroundOptions = [...this.getBackgroundOptions].reverse();
    this.backgroundOptionsCopy = this.backgroundOptions;
    const reversedArray = [...this.getBackgroundOptions].reverse();
    for (let i = 0; i < reversedArray.length; i++) {
      if (reversedArray[i].displayName == "black_background.png") {
        let blackBackground = Object.assign({}, reversedArray[i]);
        reversedArray.splice(i, 1);
        reversedArray.unshift(blackBackground)
      }
      if (reversedArray[i].displayName == "white_background.png") {
        let whiteBackground = Object.assign({}, reversedArray[i]);
        reversedArray.splice(i, 1);
        reversedArray.unshift(whiteBackground)
      }
    }
    this.backgroundOptions = reversedArray;
    this.backgroundOptionsCopy = this.backgroundOptions;

    this.wheelOverlayOptions = this.foregroundOptions;
    this.wheelOverlayOptionsCopy = this.foregroundOptions;
    this.backgroundOverlayOptions = this.backgroundOptions;
    this.backgroundOverlayOptionsCopy = this.backgroundOptions;

    this.segmentImageOptions = [...this.getSegmentOptions].reverse();
    this.segmentImageOptionsCopy = this.segmentImageOptions;
    this.segmentAnimationOptions = [...this.getWinningAnimationsOptions].reverse();
    this.segmentAnimationOptionsCopy = this.segmentAnimationOptions;
  },
  computed: {
    ...mapGetters([ 
      "getSelectedGame",
      "getCurrentStage",
      "getForegroundOptions",
      "getBackgroundOptions",
      "getSegmentOptions",
      "getWinningAnimationsOptions",
      "getTenant",
      "getUploadContentError",
      "getDropdownsLoaded"
    ]),
    disableBonusSwitch() {
      if (this.selectedTier == 0) {
        return this.getSelectedGame.tiers.length == 0 || this.getSelectedGame.no_of_segments == null ? "pointer-events: none; opacity: 0.5 !important;" : "";
      } else {
        return this.getSelectedGame.tiers[this.selectedTier - 1].no_of_segments == null
         || this.getSelectedGame.tiers[this.selectedTier - 1].no_of_segments == 0 
         || this.selectedTier == this.getSelectedGame.tiers.length
            ? "pointer-events: none; opacity: 0.5 !important;" : "";
      } 
    },
    yesBonusButtonClass() {
      if (this.selectedTier == 0) {
        return this.selectedSegmentDefinition != null 
          && this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition].bonusSegment
          ? 'yes_button_bonus' : 'yes_button_nobonus';
      } else {
        return this.selectedSegmentDefinition != null 
          && this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition].bonusSegment 
          ? 'yes_button_bonus' : 'yes_button_nobonus';
      } 
    },
    noBonusButtonClass() {
      if (this.selectedTier == 0) {
        return this.selectedSegmentDefinition != null 
          && !this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition].bonusSegment 
          ?  'no_button_nobonus' : 'no_button_bonus';
      } else {
        return this.selectedSegmentDefinition != null 
          && !this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition].bonusSegment 
          ?  'no_button_nobonus' : 'no_button_bonus';
      }
    },
    tiers() {
      return this.getSelectedGame.tiers.length + 1;
    },
    selectedTier: {
      get() {
        return this.getSelectedGame.selectedTier;
      },
      set(index) {
        if (index == undefined) return;
        this.selectedSegmentDefinition = 0;
        this.updateSelectedTier(index);
        const noOfSegments = index == 0 ? this.getSelectedGame.no_of_segments : this.getSelectedGame.tiers[index - 1].no_of_segments;
        
        this.changeSelectedTier(noOfSegments);
      },
    },
    segmentChoice: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.no_of_segments;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] ? this.getSelectedGame.tiers[this.selectedTier - 1].no_of_segments : null;
      },
      set() {
        // First choice should be set here but updates must go through dialog confirmation first.
      },
    },
    disableField() {
      return this.segmentChoice == null || this.segmentChoice == 0;
    },
    tierOptions() {
      return this.getTierOptions;
    },
    foregroundContent: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.foreground &&
            this.getSelectedGame.foreground.asset
            ? this.getSelectedGame.foreground.asset
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].foreground &&
          this.getSelectedGame.tiers[this.selectedTier - 1].foreground.asset
          ? this.getSelectedGame.tiers[this.selectedTier - 1].foreground.asset
              
          : null;
      },
      set(foregroundContent) {
        this.updateForegroundContent({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          foregroundContent: foregroundContent,
        });
      },
    },
    foregroundContentSize: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.foreground
            ? this.getSelectedGame.foreground.assetSize
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].foreground
          ? this.getSelectedGame.tiers[this.selectedTier - 1].foreground
              .assetSize
          : null;
      },
      set: _.debounce(function (assetSize) {
        if (assetSize == null || assetSize == '') assetSize = 50;
        if (!(/^[0-9]*$/.test(assetSize))) return;
        this.updateForegroundContentSize({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          assetSize: assetSize,
        });
      }, 500),
    },
    backgroundContent: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.background &&
            this.getSelectedGame.background.asset
            ? this.getSelectedGame.background.asset
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].background &&
          this.getSelectedGame.tiers[this.selectedTier - 1].background.asset
          ? this.getSelectedGame.tiers[this.selectedTier - 1].background.asset
              
          : null;
      },
      set(backgroundContent) {
        this.updateBackgroundContent({
          tier: this.selectedTier,
          backgroundContent: backgroundContent,
        });
      },
    },
    backgroundContentSize: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.background
            ? this.getSelectedGame.background.assetSize
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].background
          ? this.getSelectedGame.tiers[this.selectedTier - 1].background
              .assetSize
          : null;
      },
      set: _.debounce(function (assetSize) {
        if (assetSize == null || assetSize == '') assetSize = 50;
        if (!(/^[0-9]*$/.test(assetSize))) return;
        this.updateBackgroundContentSize({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          assetSize: assetSize,
        });
      }, 500),
    },
    wheelOverlayContent: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.wheelOverlay &&
            this.getSelectedGame.wheelOverlay.asset
            ? this.getSelectedGame.wheelOverlay.asset
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].wheelOverlay &&
          this.getSelectedGame.tiers[this.selectedTier - 1].wheelOverlay.asset
          ? this.getSelectedGame.tiers[this.selectedTier - 1].wheelOverlay.asset
              
          : null;
      },
      set(wheelOverlayContent) {
        this.updateWheelOverlayContent({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          wheelOverlayContent: wheelOverlayContent,
        });
      },
    },
    wheelOverlayContentSize: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.wheelOverlay
            ? this.getSelectedGame.wheelOverlay.assetSize
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].wheelOverlay
          ? this.getSelectedGame.tiers[this.selectedTier - 1].wheelOverlay
              .assetSize
          : null;
      },
      set: _.debounce(function (assetSize) {
        if (assetSize == null || assetSize == '') assetSize = 50;
        if (!(/^[0-9]*$/.test(assetSize))) return;
        this.updateWheelOverlayContentSize({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          assetSize: assetSize,
        });
      }, 500),
    },
    backgroundOverlayContent: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.backgroundOverlay &&
            this.getSelectedGame.backgroundOverlay.asset
            ? this.getSelectedGame.backgroundOverlay.asset
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].backgroundOverlay &&
          this.getSelectedGame.tiers[this.selectedTier - 1].backgroundOverlay.asset
          ? this.getSelectedGame.tiers[this.selectedTier - 1].backgroundOverlay.asset
              
          : null;
      },
      set(backgroundOverlayContent) {
        this.updateBackgroundOverlayContent({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          backgroundOverlayContent: backgroundOverlayContent,
        });
      },
    },
    backgroundOverlayContentSize: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.backgroundOverlay
            ? this.getSelectedGame.backgroundOverlay.assetSize
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].backgroundOverlay
          ? this.getSelectedGame.tiers[this.selectedTier - 1].backgroundOverlay
              .assetSize
          : null;
      },
      set: _.debounce(function (assetSize) {
        if (assetSize == null || assetSize == '') assetSize = 50;
        if (!(/^[0-9]*$/.test(assetSize))) return;
        this.updateBackgroundOverlayContentSize({
          tier: this.selectedTier,
          index: this.selectedSegmentDefinition,
          assetSize: assetSize,
        });
      }, 500),
    },
    winningAnimationSize: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.animationSize
            ? this.getSelectedGame.animationSize
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].animationSize
          ? this.getSelectedGame.tiers[this.selectedTier - 1].animationSize
          : null;
      },
      set: _.debounce(function (assetSize) {
        if (assetSize == null || assetSize == '') assetSize = 100;
        if (!(/^[0-9]*$/.test(assetSize))) return;
        this.updateWinningAnimationSize({
          tier: this.selectedTier,
          assetSize: assetSize,
        });
      }, 500),
    },
    winningAnimationDuration: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.animation.winningAnimationDuration
            ? this.getSelectedGame.animation.winningAnimationDuration
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].animation.winningAnimationDuration
          ? this.getSelectedGame.tiers[this.selectedTier - 1].animation.winningAnimationDuration
          : null;
      },
      set: _.debounce(function (winningAnimationDuration) {
        if (winningAnimationDuration == null || winningAnimationDuration == '') winningAnimationDuration = 100;
        if (!(/^[0-9]*$/.test(winningAnimationDuration))) return;
        this.updateWinningAnimationDuration({
          tier: this.selectedTier,
          winningAnimationDuration: winningAnimationDuration,
        });
      }, 500),
    },
    winningAnimationDelay: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.animation.winningAnimationDelay
            ? this.getSelectedGame.animation.winningAnimationDelay
            : null;
        }
        return this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].animation.winningAnimationDelay
          ? this.getSelectedGame.tiers[this.selectedTier - 1].animation.winningAnimationDelay
          : null;
      },
      set: _.debounce(function (winningAnimationDelay) {
        if (winningAnimationDelay == null || winningAnimationDelay == '') winningAnimationDelay = 100;
        if (!(/^[0-9]*$/.test(winningAnimationDelay))) return;
        this.updateWinningAnimationDelay({
          tier: this.selectedTier,
          winningAnimationDelay: winningAnimationDelay,
        });
      }, 500),
    },
    segments() {
      return this.selectedTier == 0
        ? this.getSelectedGame.segments
        : this.getSelectedGame.tiers[this.selectedTier - 1].segments;
    },
    segmentDefinition() {
      if (!this.selectedSegmentDefinition >= 0 || !this.selectedTier >= 0) {
        return null;
      }
      if (this.selectedTier == 0) {
        return this.getSelectedGame.segment_definitions
          ? this.getSelectedGame.segment_definitions[
              this.selectedSegmentDefinition
            ]
          : null;
      }
      return this.getSelectedGame.tiers &&
        this.getSelectedGame.tiers[this.selectedTier - 1] &&
        this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions
          .length >= 0
        ? this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[
            this.selectedSegmentDefinition
          ]
        : null;
    },
    segmentDefinitions() {
      if (this.selectedTier == 0) {
        return this.getSelectedGame.segment_definitions || [];
      }
      return this.getSelectedGame.tiers &&
        this.getSelectedGame.tiers[this.selectedTier - 1]
        ? this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions
        : [];
    },
    segmentDefinitionCount() {
      if (!this.selectedTier >= 0) {
        return null;
      }
      if (this.selectedTier == 0) {
        return this.getSelectedGame.segment_definitions
          ? this.getSelectedGame.segment_definitions.length
          : null;
      }
      return this.getSelectedGame.tiers &&
        this.getSelectedGame.tiers[this.selectedTier - 1] &&
        this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions
        ? this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions
            .length
        : null;
    },
    segmentName: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.segment_definitions &&
            this.getSelectedGame.segment_definitions[
              this.selectedSegmentDefinition
            ]
            ? this.getSelectedGame.segment_definitions[
                this.selectedSegmentDefinition
              ].name
            : null;
        }
        return this.getSelectedGame.tiers &&
          this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1]
            .segment_definitions &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[
            this.selectedSegmentDefinition
          ]
          ? this.getSelectedGame.tiers[this.selectedTier - 1]
              .segment_definitions[this.selectedSegmentDefinition].name
          : null;
      },
      set: _.debounce(function (name) {
        this.updateSegmentDefinitionName({
          name: name.trim(),
          index: this.selectedSegmentDefinition,
          tier: this.selectedTier,
        });
      }, 500),
    },
    segmentText: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.segment_definitions &&
            this.getSelectedGame.segment_definitions[
              this.selectedSegmentDefinition
            ]
            ? this.getSelectedGame.segment_definitions[
                this.selectedSegmentDefinition
              ].text
            : null;
        }
        return this.getSelectedGame.tiers &&
          this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1]
            .segment_definitions &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[
            this.selectedSegmentDefinition
          ]
          ? this.getSelectedGame.tiers[this.selectedTier - 1]
              .segment_definitions[this.selectedSegmentDefinition].text
          : null;
      },
      set: _.debounce(function (text) {
        this.updateSegmentDefinitionText({
          text: text.trim(),
          index: this.selectedSegmentDefinition,
          tier: this.selectedTier,
        });
      }, 500),
    },
    segmentImage: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.segment_definitions &&
            this.getSelectedGame.segment_definitions[
              this.selectedSegmentDefinition
            ] &&
            this.getSelectedGame.segment_definitions[
              this.selectedSegmentDefinition
            ].image 
            ? this.getSelectedGame.segment_definitions[
                this.selectedSegmentDefinition
              ].image
            : null;
        }
        return this.getSelectedGame.tiers &&
          this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1]
            .segment_definitions &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[
            this.selectedSegmentDefinition
          ] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[
            this.selectedSegmentDefinition
          ].image
          ? this.getSelectedGame.tiers[this.selectedTier - 1]
              .segment_definitions[this.selectedSegmentDefinition].image
          : null;
      },
      set() {},
    },
    segmentAnimation: {
      get() {
        if (this.selectedTier == 0) {
          return this.getSelectedGame.segment_definitions &&
            this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition] &&
            this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition].animation
            ? this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition].animation
            : null;
        }
        return this.getSelectedGame.tiers &&
          this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition].animation
          ? this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition].animation
          : null;
      },
      set() {
        // updated via @change function
      },
    },
    segmentBonus: {
      get() {
        if (this.selectedTier == 0) {
          let bonusSegment = this.getSelectedGame.segment_definitions &&
            this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition]
            ? this.getSelectedGame.segment_definitions[this.selectedSegmentDefinition].bonusSegment
            : null;
          return bonusSegment == true ? 0 : 1;
        }
        return this.getSelectedGame.tiers &&
          this.getSelectedGame.tiers[this.selectedTier - 1] &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions &&
          this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition]
          ? this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions[this.selectedSegmentDefinition].bonusSegment
          : null;
      },
      set(bonusSegment) {
        if (this.getSelectedGame.tiers.length == 0) {
          return;
        }
        bonusSegment = bonusSegment == 0 ? true : false;
        this.updateSegmentDefinitionBonus({
          bonusSegment: bonusSegment,
          index: this.selectedSegmentDefinition,
          tier: this.selectedTier,
        });
      },
    },
  },
  methods: {
    ...mapActions([
      "addNewSegmentDefinition",
      "updateSegmentDefinitionName",
      "updateSegmentDefinitionText",
      "updateSegmentDefinitionBonus",
      "updateSegmentChoice",
      "updateSegmentImage",
      "updateSegmentAnimation",
      "updateForegroundContent",
      "updateForegroundContentSize",
      "updateBackgroundContent",
      "updateBackgroundContentSize",
      "updateWheelOverlayContent",
      "updateWheelOverlayContentSize",
      "updateBackgroundOverlayContent",
      "updateBackgroundOverlayContentSize",
      "updateWinningAnimationSize",
      "updateWinningAnimationDuration",
      "updateWinningAnimationDelay",
      "getContentByPrefixAndTenant",
      "updateSelectedTier",
      "deleteSegmentDefinition",
      "updateUploadContentError",
      "clearSegmentOptions"
    ]),

    searchForeground() {
      if (!this.searchForegroundTerm) {
        this.foregroundOptions = this.foregroundOptionsCopy;
      }
      
      this.foregroundOptions = this.foregroundOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchForegroundTerm.toLowerCase()) > -1;
      });
    },
    searchBackground() {
      if (!this.searchBackgroundTerm) {
        this.backgroundOptions = this.backgroundOptionsCopy;
      }

      this.backgroundOptions = this.backgroundOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchBackgroundTerm.toLowerCase()) > -1;
      });
    },
    searchWheelOverlay() {
      if (!this.searchWheelOverlayTerm) {
        this.wheelOverlayOptions = this.wheelOverlayOptionsCopy;
      }
      
      this.wheelOverlayOptions = this.wheelOverlayOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchWheelOverlayTerm.toLowerCase()) > -1;
      });
    },
    searchBackgroundOverlay() {
      if (!this.searchBackgroundOverlayTerm) {
        this.backgroundOverlayOptions = this.backgroundOverlayOptionsCopy;
      }

      this.backgroundOverlayOptions = this.backgroundOverlayOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchBackgroundOverlayTerm.toLowerCase()) > -1;
      });
    },
    searchSegmentImage() {
      if (!this.searchSegmentImageTerm) {
        this.segmentImageOptions = this.segmentImageOptionsCopy;
      }
      
      this.segmentImageOptions = this.segmentImageOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchSegmentImageTerm.toLowerCase()) > -1;
      });
    },
    searchSegmentAnimation() {
      if (!this.searchSegmentAnimationTerm) {
        this.segmentAnimationOptions = this.segmentAnimationOptionsCopy;
      }
      
      this.segmentAnimationOptions = this.segmentAnimationOptionsCopy.filter((option) => {
        const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
        return displayName.toLowerCase().indexOf(this.searchSegmentAnimationTerm.toLowerCase()) > -1;
      });
    },
    tierChipClass(index) {
      return index == this.selectedTier ? 'primary' : ''
    },
    setBonusSegment(bonusSegment) {
      if (this.getSelectedGame.tiers.length == 0) {
        return;
      }
      this.updateSegmentDefinitionBonus({
        bonusSegment: bonusSegment,
        index: this.selectedSegmentDefinition,
        tier: this.selectedTier,
      });
    },
    numberBetweenOneAndHundred(value) {
      if (value === null || value == '') {
        return true; // allow empty value
      }
      if (!(/^[0-9]*$/.test(value))) {
        return 'Value must be a number between 1 and 100';
      }
      const num = parseFloat(value);
      return !isNaN(num) && num >= 1 && num <= 100 || 'Value must be a number between 1 and 100';
    },
    numberGreaterThanZero(value) {
      if (value === null || value == '') {
        return true; // allow empty value
      }
      if (!(/^[0-9]*$/.test(value))) {
        return 'Value must be a number greater than 0';
      }
      const num = parseFloat(value);
      return !isNaN(num) && num >= 1 || 'Value must be a number greater than 0';
    },
    isContentFilesValid(file) {
      if (file.size > 100000000) {
        this.$toast.error("File size should not exceed 100 MB");
        return false;
      }
      if (!file.type.includes("image") && !file.name.includes(".webm") && !file.name.includes(".mp4") && !file.name.includes(".svg")) {
        this.$toast.error("File format must be an image, webm or mp4");
        return false;
      }
      if (file.name.includes(".gif")) {
        this.$toast.error("File must not be a GIF");
        return false;
      }
      return true;
    },
    isSegmentImageFileValid(file) {
      if (file.size > 100000000) {
        this.$toast.error("File size should not exceed 100 MB");
        return false;
      }
      if (!file.type.includes("image")) {
        this.$toast.error("File must be an image");
        return false;
      }
      if (file.name.includes(".gif")) {
        this.$toast.error("File must not be a GIF");
        return false;
      }
      return true;
    },
    deleteSelectedSegmentDefinition() {
      this.deleteSegmentDialog = false;
      if (this.selectedTier == 0 && this.getSelectedGame.segment_definitions.length == 3) {
        return;
      } 
      if (!this.selectedTier == 0 && this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions.length == 3) {
        return;
      }      
      this.deleteSegmentDefinition({
        index: this.selectedSegmentDefinition,
        tier: this.selectedTier,
      });
      this.selectedSegmentDefinition = 0;
    },
    triggerSegmentUpdateConfirmation(segmentChoice) {
      this.segmentToUpdate = segmentChoice;
      if(this.segmentChoice == null || this.segmentChoice == 0){
        this.updateSegmentCount();
      }
      else{
        this.warningDialog = true;
      }
    },
    async updateSegmentCount() {
      this.selectedSegmentDefinition = 0;
      this.updateSegmentChoice({
        noOfSegments: this.segmentToUpdate,
        tierIndex: this.selectedTier,
      });
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${this.segmentToUpdate}`, tenantId: this.getTenant, update:"updateSegmentOptions"});
      this.segmentImageOptions = [...this.getSegmentOptions].reverse();
      this.segmentImageOptionsCopy = this.segmentImageOptions;
      this.segmentToUpdate = null;
      this.warningDialog = false;
    },
    async changeSelectedTier(noOfSegments) {
      this.selectedSegmentDefinition = 0;
      this.clearSegmentOptions();
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${noOfSegments}`, tenantId: this.getTenant, update:"updateSegmentOptions"});
      this.segmentImageOptions = [...this.getSegmentOptions].reverse();
      this.segmentImageOptionsCopy = this.segmentImageOptions;
    },
    cancelSegmentUpdate() {
      this.segmentToUpdate = null;
      this.warningDialog = false;
    },
    selectSegmentDefinition(index) {
      if (!index) return;
      this.selectedSegmentDefinition = index;
    },
    async onForegroundFilesUploaded() {
      for (let i = 0; i < this.foregroundFiles.length; i++) {
        if (!this.isContentFilesValid(this.foregroundFiles[i])) {
          this.foregroundFiles = [];
          return;
        }
      }

      this.foregroundUploading = true;
      this.foregroundUploadProgress = 0;
      this.foregroundUploadedCount = 0;

      for (let i = 0; i < this.foregroundFiles.length; i++) {
        const file = this.foregroundFiles[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise((resolve) => {
          reader.onload = async () => {
            const base64 = reader.result;

            await this.$store.dispatch("uploadContent", {
              type: "animation",
              displayName: file.name,
              prefix: `${this.getTenant}/content/foreground`,
              file: file,
              base64: base64,
              commitMethod: "setForegroundOptions",
            });
            await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/content/foreground`, tenantId: this.getTenant, update:"updateForegroundOptions"});

            this.foregroundUploadedCount++;
            this.foregroundUploadProgress = Math.round(
              ((i + 1) / this.foregroundFiles.length) * 100
            );
            this.foregroundOptions = [...this.getForegroundOptions].reverse();
            this.foregroundOptionsCopy = this.foregroundOptions;
            this.searchForeground(); 
            this.wheelOverlayOptions = this.foregroundOptions;
            this.wheelOverlayOptionsCopy = this.foregroundOptions;
            this.searchWheelOverlay();
            resolve();
          };
        });
      }

      this.foregroundUploading = false;
      this.foregroundUploadProgress = 0;
      this.foregroundUploadedCount = 0;
    },
    handleForegroundDragOver(event) {
      event.preventDefault();
    },
    handleForegroundDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.foregroundFiles = [...this.foregroundFiles, ...files];
      this.onForegroundFilesUploaded();
    },
    async onBackgroundFilesUploaded() {
      for (let i = 0; i < this.backgroundFiles.length; i++) {
        if (!this.isContentFilesValid(this.backgroundFiles[i])) {
          this.backgroundFiles = [];
          return;
        }
      }
      this.backgroundUploading = true;
      this.backgroundUploadProgress = 0;
      this.backgroundUploadedCount = 0;

      for (let i = 0; i < this.backgroundFiles.length; i++) {
        const file = this.backgroundFiles[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise((resolve) => {
          reader.onload = async () => {
            const base64 = reader.result;

            await this.$store.dispatch("uploadContent", {
              type: "animation",
              displayName: file.name,
              prefix: `${this.getTenant}/content/background`,
              file: file,
              base64: base64,
              commitMethod: "setBackgroundOptions",
            });
            await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/content/background`, tenantId: this.getTenant, update:"updateBackgroundOptions"});

            this.backgroundUploadedCount++;
            this.backgroundUploadProgress = Math.round(
              ((i + 1) / this.backgroundFiles.length) * 100
            );
            this.backgroundOptions = [...this.getBackgroundOptions].reverse();
            this.backgroundOptionsCopy = this.backgroundOptions;
            this.searchBackground(); 
            this.backgroundOverlayOptions = this.backgroundOptions;
            this.backgroundOverlayOptionsCopy = this.backgroundOptions;
            this.searchBackgroundOverlay();
            resolve();
          };
        });
      }

      this.backgroundUploading = false;
      this.backgroundUploadProgress = 0;
      this.backgroundUploadedCount = 0;
    },
    handleBackgroundDragOver(event) {
      event.preventDefault();
    },
    handleBackgroundDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.backgroundFiles = [...this.backgroundFiles, ...files];
      this.onBackgroundFilesUploaded();
    },
    async onSegmentContentUploaded() {
      for (let i = 0; i < this.segmentFiles.length; i++) {
        if (!this.isSegmentImageFileValid(this.segmentFiles[i])) {
          this.segmentFiles = [];
          return;
        }
      }
      this.segmentUploading = true;
      this.segmentUploadProgress = 0;
      this.segmentUploadedCount = 0;

      for (let i = 0; i < this.segmentFiles.length; i++) {
        const file = this.segmentFiles[i];
        const segmentCount = this.segmentChoice;
        //const segmentIndex = this.selectedSegmentDefinition;

        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise((resolve) => {
          reader.onload = async () => {
            const base64 = reader.result;

            await this.$store.dispatch("uploadContent", {
              type: `${segmentCount}_segment`,
              displayName: file.name,
              prefix: `${this.getTenant}/segment/${segmentCount}`,
              file: file,
              base64: base64,
              commitMethod: "setSegmentOptions",
            });
            await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${segmentCount}`, tenantId: this.getTenant, update:"updateSegmentOptions"});

            this.uploadedCount++;
            this.uploadProgress = Math.round(((i + 1) / this.segmentFiles.length) * 100);
            this.segmentImageOptions = [...this.getSegmentOptions].reverse();
            this.segmentImageOptionsCopy = this.segmentImageOptions;
            this.searchSegmentImage();
            resolve();
          };
        });
      }

      this.segmentUploading = false;
      this.segmentUploadProgress = 0;
      this.segmentUploadedCount = 0;
    },
    handleSegmentContentDragOver(event) {
      event.preventDefault();
    },
    handleSegmentContentDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.segmentFiles = [...this.segmentFiles, ...files];
      this.onSegmentContentUploaded();
    },
    async onSegmentAnimationsUploaded() {
      for (let i = 0; i < this.segmentAnimationFiles.length; i++) {
        if (!this.isContentFilesValid(this.segmentAnimationFiles[i])) {
          this.segmentAnimationFiles = [];
          return;
        }
      }
      this.segmentAnimationUploading = true;
      this.segmentAnimationUploadProgress = 0;
      this.segmentAnimationUploadedCount = 0;

      for (let i = 0; i < this.segmentAnimationFiles.length; i++) {
        const file = this.segmentAnimationFiles[i];

        const reader = new FileReader();
        reader.readAsDataURL(file);

        await new Promise((resolve) => {
          reader.onload = async () => {
            const base64 = reader.result;

            await this.$store.dispatch("uploadContent", {
              type: "animation",
              displayName: file.name,
              prefix: `${this.getTenant}/segment/winning-animation`,
              file: file,
              base64: base64,
              commitMethod: "setWinningAnimationsOptions",
            });
            await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/winning-animation`, tenantId: this.getTenant, update:"updateWinningSegmentAnimations"})

            this.uploadedCount++;
            this.uploadProgress = Math.round(((i + 1) / this.segmentAnimationFiles.length) * 100);
            this.segmentAnimationOptions = [...this.getWinningAnimationsOptions].reverse();
            this.segmentAnimationOptionsCopy = this.segmentAnimationOptions;
            this.searchSegmentImage();
            resolve();
          };
        });
      }

      this.segmentAnimationUploading = false;
      this.segmentAnimationUploadProgress = 0;
      this.segmentAnimationUploadedCount = 0;
    },
    handleSegmentAnimationDragOver(event) {
      event.preventDefault();
    },
    handleSegmentAnimationDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.segmentAnimationFiles = [...this.segmentAnimationFiles, ...files];
      this.onSegmentAnimationsUploaded();
    },
    selectSegmentImage(item) {
      this.updateSegmentImage({
        tier: this.selectedTier,
        index: this.selectedSegmentDefinition,
        image: item,
      });
    },
    selectSegmentAnimation(item) {
      this.updateSegmentAnimation({
        tier: this.selectedTier,
        index: this.selectedSegmentDefinition,
        animation: item,
      });
    },
    addSegment() {
      if (this.selectedTier == 0 && this.getSelectedGame.segment_definitions.length == this.getSelectedGame.segments.length) {
        return;
      } else if (!this.selectedTier == 0 && this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions.length == this.getSelectedGame.tiers[this.selectedTier - 1].segments.length) {
         return;
      }
      this.addNewSegmentDefinition({ tier: this.selectedTier });
      this.selectedSegmentDefinition = this.selectedTier == 0 ? this.getSelectedGame.segment_definitions.length - 1 : this.getSelectedGame.tiers[this.selectedTier - 1].segment_definitions.length - 1;
    },
    previousStep() {
      this.$emit("back");
    },
    cancel() {
      this.$emit("cancel");
    },
    assetNameFormatted(item) {
      if (item == null || item.displayName == null) return '';
      return item.displayName.substring(0, item.displayName.lastIndexOf('.'));
    },
  },
  watch: {
    getUploadContentError(newError) {
      if (newError == "null") return;
        this.$toast.error(newError);
        this.updateUploadContentError("null");
    }
  }
};
</script>

<style scoped>
.roundBorder {
  border: 1px solid black;
  border-radius: 1em;
}

.form_container {
  overflow-y: scroll; /* add scroll when the height exceeds */
}

.form_container::-webkit-scrollbar {
  display: none;
}

.file_upload ::v-deep .v-input__slot {
  height: 100px !important;
  width: 100%;
  background: #f8f8f8 !important;
  border-radius: 15px;
  overflow: auto;
}

.file_upload ::v-deep .v-label{
  color: #808080;
}

.v-file-input__slot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #c4c4c4;
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.active{
    background: black !important;
    color: white !important;
}
#yesBonusButton.yes_button_nobonus.v-btn{
    background: #E2FBE0 !important;
    opacity: 100% !important;
    padding-right: 10px !important;
    margin-right: -4px;
    border-width: thin;
    border-color: #64B65C !important;
}
.yes_button_bonus.v-btn{
    opacity: 100% !important;
    border-top-right-radius: inherit !important;
    border-bottom-right-radius: inherit !important;
    z-index: 1;
    background: black !important;
    color: white !important;
}
.no_button_nobonus.v-btn{
    opacity: 100% !important;
    border-top-left-radius: inherit !important;
    border-bottom-left-radius: inherit !important;
    z-index: 1;
    background: black !important;
    color: white !important;
}
#noBonusButton.no_button_bonus.v-btn{
    background: #F9CFCF !important;
    opacity: 100% !important;
    padding-left: 10px !important;
    margin-left: -3px;
    border-width: thin;
    border-color: #F25858 !important;
}
.segment_value_tooltip_width {
  max-width: 800px;
}
</style>
