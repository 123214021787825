<template>
    <div class="layer" :style="[rotation]">
        <img v-if="image" :src="getLayerAsset(layer.asset)" class="center" :style="[sizeStyle]">
    </div>
</template>

<script>
export default {
    props: {
        layer: {
            type: Object,
            default() {
                return {
                    asset: null,
                    assetSize: null,
                    rotation: 'none'
                }
            }
        },
        turn: {
            type: Number,
            default: 0
        }
    },
    computed: {
        image() {
            if (!this.layer) { return '' }
            return /\.(jpg|jpeg|png)$/i.test(this.layer.asset);
        },
        sizeStyle() {
            if (!this.layer) { return '' }
            if (this.layer.assetSize == 'full') {
                return { height: '100%' };
            } else if (Number(this.layer.assetSize)) {
                return { height: `${this.layer.assetSize}%` };
            }
            return '';
        },
        rotation() {
            if (!this.layer.rotation || this.layer.rotation == 'none') {
                return {};
            }
            else if (this.layer.rotation == 'counter') {
                return { transform: "rotate(" + this.turn + "turn)" };
            } else if (this.layer.rotation == 'doubleSpeed') {
                let reverseTurn = 1 - this.turn;
                return { transform: "rotate(" + reverseTurn + "turn)" };
            } else if (this.layer.rotation == 'reverse') {
                return { transform: "rotate(" + this.turn * 2 + "turn)" };
            }
            return 0;
        }
    }
}
</script>

<style scoped>
.layer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>