<template>
  
  <v-card class="elevation-0">
    <div id="loadingOverlay" :style="loadingOverlayStyle">
        <div class="loader" :style="spinnerStyle"></div>
    </div>

    <v-card-title class="font-weight-normal">{{ $t('assets.title') }}</v-card-title>
    <v-card-subtitle class="font-weight-light">{{ $t('assets.subtitle') }}</v-card-subtitle>  
    
    <v-card class="elevation-0">
      <v-card-title class="mt-0 pt-0 pb-0 mb-0">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search Asset by Name"
          single-line
          hide-details
          class="mt-0 pt-0 pb-0 mb-0 searchWidth"
        ></v-text-field>
        <v-btn class="black white--text ml-4 mb-2 text-capitalize align-self-center" small @click="addAssetDialog = true"><v-icon class="" small>mdi-plus</v-icon>{{ $t('details.addNew') }}</v-btn>
        <v-btn 
          class="red white--text ml-3 mb-2 text-capitalize align-self-center" 
          small 
          @click="triggerDeleteConfirmation()"
          :style="disableDeleteBtnStyle"
          >
          Delete
        </v-btn>
      </v-card-title>

      <v-row>
        <v-select
          v-model="assetTypeFilter"   
          :items="assetTypes"
          class="selectWidth pt-5 ml-2"
          label="Asset Type"
          outlined
          dense
          clearable
        >
        </v-select>
        <v-select
          v-model="fileTypeFilter"   
          :items="fileTypes"
          class="selectWidth pt-5 ml-2"
          label="File Type"
          outlined
          dense
          clearable
        >
        </v-select>
        <v-select
          v-model="gameNameFilter"   
          :items="gameNames"
          item-text="name"
          class="selectWidth pt-5 ml-2"
          label="Game Name"
          outlined
          dense
          clearable
        >
        </v-select>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="tableItems"
        :search="search"
        :loading="loading"
        show-select
        v-model="selectedItems"
        item-key="id"
        :items-per-page="10"
      >
        <template v-slot:item.displayName="{ item }">
          {{ assetNameFormatted(item.displayName) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            :style="disableActionsStyle"
            @click="triggerDeleteConfirmation(item.id)"
          >
            mdi-delete
          </v-icon>
          <v-icon
            small
            :style="disableActionsStyle"
            @click="downloadAsset(item)"
          >
            mdi-download
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    
    
    <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline align-self-center text-center">Are you sure you want to delete?</v-card-title>
          <v-card-actions>
            <v-btn color="primary" text @click="deleteAsset">Yes</v-btn>
            <v-btn color="red" text @click="deleteDialog = false;">No</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="addAssetDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('details.newAsset') }}</span>
        </v-card-title>

        <v-form @submit.prevent="newAssetForm">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="8"
                >
                  <v-select
                    v-model="newAssetDTO.assetType"   
                    :items="assetTypes"
                    class="selectWidth pt-2 ml-2"
                    label="Asset Type"
                    outlined
                    dense
                  >
                  </v-select>
                  <v-file-input
                    v-model="assetFiles"
                    :label="$t('details.upload_files_placeholder')"
                    accept="image/*, .mp4, .webm"
                    outlined
                    show-size
                    counter
                    class="file_upload"
                    prepend-icon=""
                    @change="onNewAssetFileUploaded"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip dark label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="addAssetDialog = false"
            >
              {{ $t('action.cancel') }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="disableAssetUploadBtn"
            >
              {{ $t('action.upload') }}
            </v-btn>

          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-card>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'AssetLibrary',
  data () {
    return {
      deleteDialog: false,
      updateAssetDialog: false,
      addAssetDialog: false,
      newAssetDTO: {},
      fileTypes: [
        'Animation',
        'Image'
      ],
      assetTypes: [
        'Idle Animation',
        'Foreground',
        'Background',
        'Winning Animation',
        '54 Segment',
        '27 Segment',
        '18 Segment',
        '9 Segment',
        '3 Segment',
      ],
      gameNames: [],
      fileTypeFilter: '',
      assetTypeFilter: '',
      gameNameFilter: '',
      assetFiles: [],
      deleteAssetId: null,
      loading: true,
      downloading: false,
      tableItems: [],
      selectedItems: [],
      headers: [
          { text: 'Name', align: 'start', value: 'displayName', search: 'name'},
          { text: 'Date Created', value: 'dateCreated', filterable: false, sort: this.sortDate
          },
          { text: 'Asset Type', value: 'assetType', filterable: false, },
          { text: 'File Type', value: 'mimeType', filterable: false, },
          { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
      fileUploaded: false,
    }
  },
  async created() {
    if (this.getAssetList != null && this.getAssetList.length > 0) return;
    await this.getAssets();
    if (this.getGamesList.length == 0) {
      await this.getAllWheels({tenantId: this.getTenant, update: 'addNewGameAction'});
    }
    this.gameNames = this.getGamesList.slice();
    this.loading = false;
    this.tableItems = this.getAssetList.slice();

  },
  mounted() {
    if (this.getAssetList != null && this.getAssetList.length > 0) {
      this.loading = false;
    }
  },
  watch: {
    fileTypeFilter() {
      this.runFilters();
    },
    assetTypeFilter() {
      this.runFilters();
    },
    gameNameFilter() {
      this.runFilters();
    },
    getUploadContentError(newError) {
      if (newError == "null") return;
      if (newError.includes("413")) {
        this.$toast.error("Error: Asset size too large");
      } else {
        this.$toast.error(newError);
      }
      this.updateUploadContentError("null");
    }
  },
  methods: {
    ...mapActions(["deleteContent", "updateGame", "deleteContents","downloadContent", "formatSharedAssetList", "uploadContent", "getAssets", "getAllWheels", "updateUploadContentError"]),
    
    sortDate(a, b) {
      const aSplitDate = a.split('/');
      const bSplitDate = b.split('/');
      const aNewDate = aSplitDate[1] + '/' + aSplitDate[0] + '/' + aSplitDate[2];
      const bNewDate = bSplitDate[1] + '/' + bSplitDate[0] + '/' + bSplitDate[2];
      let aDate = new Date(aNewDate).getTime();
      let bDate = new Date(bNewDate).getTime();

      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0;
      }
    },
    runFilters() {
      this.tableItems = this.getAssetList.slice();
      let thiz = this;
      
      if (this.assetTypeFilter != null && this.assetTypeFilter != '') {
        this.tableItems = this.tableItems.filter(function (asset) {
          return asset.assetType == thiz.assetTypeFilter;
        });
      }

      if (this.fileTypeFilter != null && this.fileTypeFilter != '') {
        this.tableItems = this.tableItems.filter(function (asset) {
          const fileType = thiz.fileTypeFilter == 'Animation' ? 'video' : 'image';
          return asset.mimeType.split('/')[0] == fileType;
        });
      }

      if (this.gameNameFilter != null && this.gameNameFilter != '') {
        this.tableItems = this.tableItems.filter(function (asset) {
          const game = thiz.getGamesList.find(item => item.displayName == thiz.gameNameFilter);
          return asset.groupId == game.groupId;
        });
      }

    },
    assetNameFormatted(name) {
      return name.substring(0, name.lastIndexOf('.'));
    },
    updateAssetDetails() {
      this.updateAssetDialog = false;
      if (this.tagFilter != null && this.tagFilter != '') {
        this.tags.push(this.tagFilter);
        this.tagFilter = "";
        this.runFilters();
      }
    },
    async onNewAssetFileUploaded() {
      if (this.isInvalidFile()) {
        return;
      }
      this.downloading = true;

      const file = this.assetFiles;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise((resolve) => {
        reader.onload = async () => {
          this.newAssetDTO.base64 = reader.result;
          this.newAssetDTO.file = file;
          this.fileUploaded = true;
          resolve();
        };
      });

      this.downloading = false;
    },
    isInvalidFile() {
      if (this.assetFiles.size > 100000000) {
        this.$toast.error("File size should not exceed 100 MB");
        this.assetFiles = [];
        return false;
      }
      if (this.assetFiles.name.includes(".gif")) {
        this.$toast.error("File must not be a GIF");
        return false;
      }

      if (this.newAssetDTO.assetType == 'Idle Animation' || this.newAssetDTO.assetType == 'Winning Animation') {
        if (!this.assetFiles.name.includes(".webm") && !this.assetFiles.name.includes(".mp4") && !this.assetFiles.name.includes(".svg")) {
          this.$toast.error("File format must be an image, webm or mp4");
          this.assetFiles = [];
          return false;
        }
      }
    },
    async newAssetForm() {
      let prefix = `${this.getTenant}/content/${this.newAssetDTO.assetType.toLowerCase()}`;
      let type = 'animation';
      if (this.newAssetDTO.assetType.includes('Segment')) {
        let segmentCount = this.newAssetDTO.assetType.trim().split(" ")[0];
        prefix = `${this.getTenant}/segment/${segmentCount}`;
        type = `${segmentCount}_segment`;
      } else if (this.newAssetDTO.assetType == 'Winning Animation') {
        prefix = `${this.getTenant}/segment/winning-animation`
      } else if (this.newAssetDTO.assetType == 'Idle Animation') {
        prefix = `${this.getTenant}/content/idle`
      }

      this.downloading = true;
      await this.$store.dispatch("uploadContent", {
        type: type,
        displayName: this.newAssetDTO.file.name,
        prefix: prefix,
        file: this.newAssetDTO.file,
        base64: this.newAssetDTO.base64,
        commitMethod: null,
      });
      this.fileUploaded = false;
      this.downloading = false;
      this.addAssetDialog = false;
      if (!this.getUploadContentError && this.getUploadContentError != "null") {
        location.reload();
      }
    },
    deleteAsset() {
      this.downloading = true;
      if (this.selectedItems.length > 0) {
        let ids = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          if (this.selectedItems[i].tenantId == "shared" && this.getTenant != 'shared') {
            this.$toast.error("Cannot delete shared assets.");
            this.deleteDialog = false;
            this.downloading = false; 
            return;
          }
          ids.push(this.selectedItems[i].id);
        }
        this.deleteContents(ids).then(() => {
          this.downloading = false;
          location.reload();
      })
      } else {
        const deleteAsset = this.getAssetList.find(a => a.id == this.deleteAssetId)
        if (deleteAsset.tenantId == "shared" && this.getTenant != 'shared') {
          this.$toast.error("Cannot delete shared assets.");
          this.deleteDialog = false;
          this.downloading = false;
          return;
        }
        this.deleteContent(this.deleteAssetId).then(() => {
          this.downloading = false;
          location.reload();
        })
      }
      this.deleteDialog = false;
    },
    triggerDeleteConfirmation(id) {
        this.deleteAssetId = id;
        this.deleteDialog = true;
    },
    async downloadAsset(asset) {
      this.downloading = true;
      await this.downloadContent(asset);
      this.downloading = false;
    }
  },
  computed: {
    ...mapGetters(["getAssetList", "getTenant", "getSharedAssetList", "getGamesList", "getUploadContentError"]),
    spinnerStyle() {
        return this.downloading ? 'pointer-events: none' : '';
    },
    loadingOverlayStyle() {
        return this.downloading ? '' : 'display: none';
    },
    disableAssetUploadBtn() {
      return this.fileUploaded == false;
    },
    disableActionsStyle() {
      return this.selectedItems.length > 0 ? 'pointer-events: none; opacity: 0.6' : ''; 
    },
    disableDeleteBtnStyle() {
      return this.selectedItems.length < 1 ? 'pointer-events: none; opacity: 0.6' : ''; 
    },
  }
}
</script>

<style scoped>
#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.selectWidth {
  max-width: 310px;
}
.searchWidth {
  max-width: 500px;
}
.file_upload ::v-deep .v-input__slot {
  height: 100px !important;
  width: 100%;
  background: #f8f8f8 !important;
  border-radius: 15px;
  overflow: auto;
}

.file_upload ::v-deep .v-label{
  color: #808080;
}
</style>