<template>
<v-app>
  <template>
    <div v-if="!isLoginPage">
      <NavigationDrawer />
      <AppBar />
      <v-divider class="background"></v-divider>
    </div>
    <v-main>
      <v-container fluid :class="isLoginPage ? 'fill-height pa-0' : ''">
        <router-view></router-view>
      </v-container>
    </v-main>
  </template>
</v-app>
</template>

<script>
import AppBar from '@/components/structural/AppBar';
import NavigationDrawer from '@/components/structural/NavigationDrawer';
import { mapGetters } from "vuex";

export default {
  name: 'App',
  components: {
    AppBar,
    NavigationDrawer
  },
  computed: {
    ...mapGetters(["isLoginPage"]),
  }
};
</script>