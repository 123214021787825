import { createLogger } from "vuex";
import Vue from 'vue'
import Vuex from 'vuex'
import game from '@/modules/game'
import login from '@/modules/login'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    login: login,
    game: game
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : [],
});
