import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import VueToast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { POSITION } from 'vue-toastification'

Vue.config.productionTip = false

Vue.use(VueToast, {
  position: POSITION.BOTTOM_CENTER,
  timeout: 2500,
  hideProgressBar: true,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
