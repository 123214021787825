const theme1 = {
      primary: "#21CFF3",
      accent: "#FF4081",
      secondary: "#21dc79",
      success: "#86af3f",
      info: "#f34fc6",
      warning: "#FB8C00",
      error: "#FF5252"
    };

  const theme2 = {
      primary: "#FFB700",
      accent: "#7CB342",
      secondary: "#689F38",
      success: "#4CAF50",
      info: "#6156d8",
      warning: "#1565C0",
      error: "#FF7043",
      background: "#F5F5F5",
      seg_3: "#5DA181",
      seg_9: "#6195D2",
      seg_18: "#e8778B",
      seg_27: "#7674BF",
      seg_54: "#D7A8FC",
      tier_1: "#FBAB91",
      tier_2: "#97DACE",
      tier_3: "#AAD0FD"
    };

export default {
  dark: false,
  themes: {
    dark: theme1,
    light: theme2,
  },
};