import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import i18n from '../plugins/i18n'
import router from '../router'

Vue.use(VueAxios, axios)

const state = {
  loading: true,
  user: null,
  tenant: localStorage.getItem("tenantId"),
  isLoginPage: false,
  loginFailed: false
};

const getters = {
  isLoading() {
    return state.loading;
  },
  isLoginPage() {
    return state.isLoginPage;
  },
  getUser() {
    return state.user;
  },
  getTenant() {
    return state.tenant;
  },
  getLoginFailed() {
    return state.loginFailed;
  }
};

const mutations = {
  setLoading(state, status) {
    state.loading = status;
  },
  setUser(state, user) {
    state.user = user;
  },
  setTenant(state, tenant) {
    state.tenant = tenant;
  },
  setIsLoginPage(state, flag) {
    state.isLoginPage = flag;
  },
  setLoginFailed(state, flag) {
    state.loginFailed = flag;
  }
};

const actions = {
  enterApp({ commit }) {
    commit("setLoading", false);
  },
  async login({ commit }, data) {
    commit("setLoginFailed", false);
    let authenticated = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.authentication_controller')}/authenticate`, { username: data.username, password: data.password }).catch( error => {
      console.log(error);
      commit("setLoginFailed", true);
    });
    if (authenticated && authenticated.status == 200 && authenticated.data.token) {
        commit("setUser", { name: data.username, image: "https://cdn.vuetifyjs.com/images/john.jpg" });
        commit("setTenant", authenticated.data.tenantId);
        localStorage.setItem("token", authenticated.data.token);
        localStorage.setItem("tenantId", authenticated.data.tenantId)
        commit("setIsLoginPage", false);
        router.push("/");
    } else {
        commit("setLoginFailed", true);
    }
  },
  setLoginPage({ commit }, flag) {
    commit("setIsLoginPage", flag);
  }
};

export default {
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
