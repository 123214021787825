<template>
  <v-card class="elevation-0">
    <div id="loadingOverlay" :style="loadingOverlayStyle">
        <div class="loader" :style="spinnerStyle"></div>
    </div>
    <v-form
      v-model="valid"
      ref="form"
      lazy-validation
    >
      <v-container fluid>
        <v-row>
          <v-col cols="5" :lg="3" class="mr-3">
            <p class="text-subtitle-2 mb-1">{{ $t('details.wheel_name') }}</p>
            <v-text-field
              v-model="wheelName"
              :label="$t('details.wheel_name_placeholder')"
              :rules="wheelNameRules"
              required
              outlined
              single-line
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="5" :lg="3" class="mr-3">
            <p class="text-subtitle-2 mb-1">{{ $t('details.wheel_description') }}</p>
            <v-text-field
              v-model="wheelDescription"
              :label="$t('details.wheel_description_placeholder')"
              :rules="wheelDescriptionRules"
              required
              outlined
              single-line
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="5" :lg="3" class="mr-3">
            <p class="text-subtitle-2 mb-1">{{ $t('details.wheel_tiers') }}</p>
            <v-select
              v-model="tiers"   
              :items="tierOptions"
              required
              outlined
              dense
              :style="disableNumTiers"
            >
            </v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="mb-6" ></v-divider>
        </v-row>

        <v-row>
          <v-col cols="6" 
            @dragover="handleDragOver"
            @drop="handleDrop">
            <p class="mb-1 text-subtitle-2">{{ $t('details.upload_animation') }}
            </p>
            <v-file-input
              v-model="idleAnimationFiles"
              :label="$t('details.upload_files_placeholder')"
              accept=".mp4, .webm"
              multiple
              outlined
              show-size
              counter
              class="file_upload"
              prepend-icon=""
              @change="onIdleAnimationFilesUploaded"
            >
              <template v-slot:selection="{ text }">
                <v-chip dark label small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="5">
            <v-progress-linear
              v-if="uploading"
              :value="uploadProgress"
              color="primary"
              height="5"
              class="ma-2"
            ></v-progress-linear>
            <p v-if="uploading" class="ml-3 text-subtitle-2 font-weight-light"> Uploading {{ uploadedCount }} of {{  idleAnimationFiles.length }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" :lg="3" class="mt-0">
            <p class="mb-2 text-subtitle-2">{{ $t('details.idle_animation') }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_idle_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-select
              v-model="idleAnimationContent"
              :items="idleAnimationOptions"
              :label="$t('details.make_selection')"
              :item-text="assetNameFormatted"
              return-object
              dense
              searchable  
              outlined
              flat
              solo
              clearable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchIdleTerm" placeholder="Search" @input="searchIdle" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="5" :lg="3">
            <p class="mb-2 text-subtitle-2 no-wrap">{{ $t('details.idle_animation_timer') }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_idle_timer_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="idleAnimationTime"
              :rules="[maxIdleTimer]"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5" :lg="3">
            <p class="mb-2 text-subtitle-2  no-wrap">{{ $t('details.idle_animation_duration') }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_idle_duration_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="idleAnimationDuration"
              :rules="[maxIdleTimer]"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col cols="5" :lg="3">
            <p class="mb-2 text-subtitle-2 no-wrap">{{ $t('details.startup_animation') }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_startup_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-select
              v-model="startupAnimationContent"
              :items="startupAnimationOptions"
              :label="$t('details.make_selection')"
              :item-text="assetNameFormatted"
              return-object
              dense
              searchable  
              outlined
              flat
              solo
              clearable
            >
              <template v-slot:prepend-item>
                <v-text-field v-model="searchStartupTerm" placeholder="Search" @input="searchStartup" class="ml-2 mr-2 pt-0"></v-text-field>
                <v-divider class=""></v-divider>
              </template>
              <template v-slot:item="{ item }">
                {{ assetNameFormatted(item) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="5" :lg="3">
            <p class="mb-2 text-subtitle-2 no-wrap">{{ $t('details.startup_animation_timer') }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_startup_timer_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="startupAnimationTime"
              :rules="[maxIdleTimer]"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="5" :lg="3">
            <p class="mb-2 text-subtitle-2 no-wrap">{{ $t('details.startup_animation_duration') }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="ml-1">mdi-help-circle</v-icon>
                </template>
                <span>{{ $t('details.upload_startup_duration_subtitle') }}</span>
              </v-tooltip>
            </p>
            <v-text-field
              v-model="startupAnimationDuration"
              :rules="[maxIdleTimer]"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="3" :lg="2">
            <p class="mb-2 text-subtitle-2 no-wrap">{{ $t('details.startup_animation_loop') }}</p>
            <v-checkbox
              v-model="startupAnimationLoop" color="primary" class="large-checkbox pl-5 ml-5"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row>
          <v-divider class="mb-8" ></v-divider>
        </v-row>

      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from 'lodash';

  export default {
    data: () => ({
      valid: false,

      idleAnimationFiles: [],
      tierOptions: [...Array(4)].map((_, i) => i + 1),

      uploading: false,
      uploadProgress: 0,
      uploadedCount: 0,
      wheelNameRules: [
        v => !!v || 'Name is required',
        v => /^[A-Za-z0-9\s_\-@!'"]*$/.test(v) || "Wheel name invalid",
      ],
      wheelDescriptionRules: [
        v => !!v || 'Description is required',
        v => /^[A-Za-z0-9\s_\-@#!.'"]*$/.test(v) || "Wheel description invalid",
      ],

      dataLoaded: false,
      searchIdleTerm: '',
      idleAnimationOptions: [],
      idleAnimationOptionsCopy: [],
      searchStartupTerm: '',
      startupAnimationOptions: [],
      startupAnimationOptionsCopy: [],
      downloading: false,
    }),
    async created() {
      
    },
    mounted(){
      this.uploadGame(true);
      this.loadAnimationOptions();
    },
    watch: {
      getUploadContentError(newError) {
        if (newError == "null") return;
        this.$toast.error(newError);
        this.updateUploadContentError("null");
      }
    },
    computed: {
      ...mapGetters(["getSelectedGame", "getSegmentDefinitions", "getTenant", "getIdleAnimationOptions", "getUploadContentError", "getDropdownsLoaded", "getGamesList"]),
      disableNumTiers() {
        return this.wheelName == null ||  this.wheelName == '' ? 'pointer-events: none; opacity: 0.5' : '';
      },
      loadingOverlayStyle() {
        return this.downloading ? '' : 'display: none';
      },
      spinnerStyle() {
        return this.downloading ? 'pointer-events: none' : '';
    },
      wheelName: {
        get() {
          return this.getSelectedGame ? this.getSelectedGame.name : null;
        },
        set: _.debounce(function(newGameName) {
          this.$store.dispatch('updateGameName', newGameName.trim());
        }, 400)
      },
      wheelDescription: {
        get() {
          return this.getSelectedGame ? this.getSelectedGame.description : null;
        },
        set: _.debounce(function(newWheelDescription) {
          this.$store.dispatch('updateGameDescription', newWheelDescription.trim());
        }, 400)
      },
      tiers: {
        get() {
          return this.getSelectedGame && this.getSelectedGame.tiers ? this.getSelectedGame.tiers.length + 1 : null;
        },
        set: _.debounce(function(tierCount) {
          if(this.numberBetweenOneAndThree(tierCount) == true) {
            this.$store.dispatch('updateNumberOfTiers', tierCount);
          }
        }, 1000)
      },
      idleAnimationContent: {
        get() {
            return this.getSelectedGame.idle &&
              this.getSelectedGame.idle.asset
              ? this.getSelectedGame.idle.asset
              : null;
        },
        set(idleAnimation) {
          this.updateIdleAnimation(idleAnimation);
        },
      },
      idleAnimationTime: {
        get() {
          return this.getSelectedGame ? this.getSelectedGame.idle.delay : "50";
        },
        set: _.debounce(function(animationTime) {
          if(this.maxIdleTimer(animationTime) == true) {
            this.$store.dispatch('updateIdleAnimationTime', animationTime.trim());
          }
        }, 100)
      },
      idleAnimationDuration: {
        get() {
          return this.getSelectedGame ? this.getSelectedGame.idle.duration : "10";
        },
        set: _.debounce(function(animationDuration) {
          if(this.maxIdleTimer(animationDuration) == true) {
            this.$store.dispatch('updateIdleAnimationDuration', animationDuration.trim());
          }
        }, 100)
      },
      startupAnimationContent: {
        get() {
            return this.getSelectedGame.animation &&
            this.getSelectedGame.animation.startupAsset
              ? this.getSelectedGame.animation.startupAsset
              : null;
        },
        set(startupAnimation) {
          this.updateStartupAnimation(startupAnimation);
        },
      },
      startupAnimationTime: {
        get() {
          return this.getSelectedGame.animation ? this.getSelectedGame.animation.startupAnimationDelay : "5";
        },
        set: _.debounce(function(animationTime) {
          if(this.maxIdleTimer(animationTime) == true) {
            this.$store.dispatch('updateStartupAnimationTime', animationTime.trim());
          }
        }, 100)
      },
      startupAnimationDuration: {
        get() {
          return this.getSelectedGame.animation ? this.getSelectedGame.animation.startupAnimationDuration : "10";
        },
        set: _.debounce(function(animationDuration) {
          if(this.maxIdleTimer(animationDuration) == true) {
            this.$store.dispatch('updateStartupAnimationDuration', animationDuration.trim());
          }
        }, 100)
      },
      startupAnimationLoop: {
        get() {
          return this.getSelectedGame.animation ? this.getSelectedGame.animation.startupAnimationLoop : false;
        },
        set: _.debounce(function(startupAnimationLoop) {
          this.$store.dispatch('updateStartupAnimationLoop', startupAnimationLoop);
        }, 100)
      }
    },
    methods: {
      ...mapActions(["updateGameStage", 
        "getContentByPrefixAndTenant", 
        "generateGameJSON", 
        "uploadWheelGame",
        "updateIdleAnimation",
        "updateIdleAnimationTime",
        "updateIdleAnimationDuration",
        "updateStartupAnimation",
        "updateStartupAnimationTime",
        "updateStartupAnimationDuration",
        "updateStartupAnimationLoop",
        "updateUploadContentError",
        "updateDropdownsLoaded",
        "getAllWheels"
      ]),

      loadAnimationOptions() {
        this.idleAnimationOptions = [...this.getIdleAnimationOptions].reverse();
        this.idleAnimationOptionsCopy = this.idleAnimationOptions;
        this.startupAnimationOptions = this.idleAnimationOptions;
        this.startupAnimationOptionsCopy = this.idleAnimationOptions;

      },
      async uploadGame() {
        if (this.getSelectedGame.wheelGameId != null) return;
        this.generateGameJSON();
        this.uploadWheelGame({'incompleteGame' : true});
      },
      isInvalidFile() {
        for (let i = 0; i < this.idleAnimationFiles.length; i++) {
          if (this.idleAnimationFiles[i].size > 100000000) {
            this.$toast.error("File size should not exceed 100 MB");
            this.idleAnimationFiles = [];
            return false;
          }
          if (!this.idleAnimationFiles[i].name.includes(".webm") && !this.idleAnimationFiles[i].name.includes(".mp4") && !this.idleAnimationFiles[i].name.includes(".svg")) {
            this.$toast.error("File format must be webm, mp4 or svg");
            this.idleAnimationFiles = [];
            return false;
          }
        }
      },
      numberBetweenOneAndThree(value) {
        if (value === null) {
          return true; // allow empty value
        }
        const num = parseFloat(value);
        return !isNaN(num) && num >= 1 && num <= 10 || 'Value must be a number between 1 and 10';
      },
      maxIdleTimer(value) {
        if (value === null || value == '') {
          return true; // allow empty value
        }
        if (!(/^[0-9]*$/.test(value))) {
          return 'Value must be a number between 1 and 3000';
        }
        const num = parseFloat(value);
        return !isNaN(num) && num >= 1 && num <= 3000 || 'Value must be a number between 1 and 3000';
      },
      async onIdleAnimationFilesUploaded() {
        if (this.isInvalidFile()) {
          return;
        }

        this.uploading = true;
        this.uploadProgress = 0;
        this.uploadedCount = 0;

        for (let i = 0; i < this.idleAnimationFiles.length; i++) {
          const file = this.idleAnimationFiles[i];
          const reader = new FileReader();
          reader.readAsDataURL(file);

          await new Promise((resolve) => {
            reader.onload = async () => {
              const base64 = reader.result;
              await this.$store.dispatch('uploadContent', { 
                  type: "animation",
                  displayName: file.name,
                  prefix: `${this.getTenant}/content/idle`,
                  file: file,
                  base64: base64,
                  commitMethod: "setIdleAnimationOptions"
              });
              await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/content/idle`, tenantId: this.getTenant, update: 'updateIdleAnimationOptions'});
              this.uploadedCount++;
              this.uploadProgress = Math.round(((i + 1) / this.idleAnimationFiles.length) * 100);
              this.idleAnimationOptions = [...this.getIdleAnimationOptions].reverse();
              this.idleAnimationOptionsCopy = this.idleAnimationOptions;
              this.startupAnimationOptions = this.idleAnimationOptions;
              this.startupAnimationOptionsCopy = this.idleAnimationOptions;
              this.searchIdle();    
              resolve();
            };
          });
        }

        this.uploading = false;
        this.uploadProgress = 0;
        this.uploadedCount = 0;
      },
      handleDragOver(event) {
        event.preventDefault();
      },
      handleDrop(event) {
        event.preventDefault();
        const files = event.dataTransfer.files;
        this.idleAnimationFiles = [...this.idleAnimationFiles, ...files];
        this.onIdleAnimationFilesUploaded();
      },
      selectIdleAnimation(item){
        this.updateIdleAnimation(item);
      },
      assetNameFormatted(item) {
        if (item == null || item.displayName == null) return '';
        return item.displayName.substring(0, item.displayName.lastIndexOf('.'));
      },
      searchIdle() {
        if (!this.searchIdleTerm) {
          this.idleAnimationOptions = this.idleAnimationOptionsCopy;
        }

        this.idleAnimationOptions = this.idleAnimationOptionsCopy.filter((option) => {
          const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
          return displayName.toLowerCase().indexOf(this.searchIdleTerm.toLowerCase()) > -1;
        });
      },
      searchStartup() {
        if (!this.searchStartupTerm) {
          this.startupAnimationOptions = this.startupAnimationOptionsCopy;
        }

        this.startupAnimationOptions = this.startupAnimationOptionsCopy.filter((option) => {
          const displayName = option.displayName.substring(0, option.displayName.lastIndexOf('.'));
          return displayName.toLowerCase().indexOf(this.searchStartupTerm.toLowerCase()) > -1;
        });
      },
    }
  }
</script>

<style scoped>
.file_upload ::v-deep .v-input__slot {
  height: 100px !important;
  width: 100%;
  background: #f8f8f8 !important;
  border-radius: 15px;
  overflow: auto;
}

.file_upload ::v-deep .v-label{
  color: #808080;
}

.v-file-input__slot::before {
  border-style: none !important;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #c4c4c4;
  font-family: 'Material Design Icons';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.large-checkbox {
  transform: scale(1.5); /* Scale the checkbox */
  transform-origin: center;}

</style>
