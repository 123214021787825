import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import theme from '@/plugins/theme'

Vue.use(Vuetify);

export default new Vuetify({
    theme: theme
});
