function randomPastelHex() {
    let R = Math.floor((Math.random() * 127) + 127);
    let G = Math.floor((Math.random() * 127) + 127);
    let B = Math.floor((Math.random() * 127) + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;      
}

var STARTING_SEGMENT_DEFINITIONS = [
    {
        id: 0,
        name: null,
        bonusSegment: false,
        image: {
            id: 0,
            file: null,
            base64: null,
            contentId: null
        },
        animation: {
            id: 0,
            file: null,
            base64: null,
            contentId: null
        },
        fillStyle: randomPastelHex()
    },
    {
        id: 1,
        name: null,
        bonusSegment: false,
        image: {
            id: 1,
            file: null,
            base64: null,
            contentId: null
        },
        animation: {
            id: 1,
            file: null,
            base64: null,
            contentId: null
        },
        fillStyle: randomPastelHex()
    },
    {
        id: 2,
        name: null,
        bonusSegment: false,
        image: {
            id: 2,
            file: null,
            base64: null,
            contentId: null
        },
        animation: {
            id: 2,
            file: null,
            base64: null,
            contentId: null
        },
        fillStyle: randomPastelHex(),
    }
];

var EMPTY_CONTENT_OBJECT = {
    contentId: null,
    file: null,
    base64: null,
    name: null,
};

var EMPTY_SEGMENT_OBJECT = {
    id: null,
    text: null,
    value: null,
    bonusSegment: false,
    image: Object.assign({}, EMPTY_CONTENT_OBJECT),
    animation: Object.assign({}, EMPTY_CONTENT_OBJECT),
    animationLength: 10,
    winningAnimationDuration: null,
    fillStyle: randomPastelHex()
};


export { randomPastelHex , STARTING_SEGMENT_DEFINITIONS, EMPTY_CONTENT_OBJECT, EMPTY_SEGMENT_OBJECT };