<template>
  <v-app-bar app flat class="secondary--background-color secondary--border-bottom" color="white" height="64">
      <v-row no-gutters justify="end">
        
        <v-col cols="auto">
          <div>
            <v-btn class="mr-12" small color="primary" @click="logoutDialog = true">Logout<v-icon class="ml-2" small>mdi-logout</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
    <v-dialog v-model="logoutDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline align-self-center text-center">Are you sure you want to logout?</v-card-title>
        <v-card-actions>
          <v-btn color="primary" text @click="logout">Yes</v-btn>
          <v-btn color="red" text @click="logoutDialog = false;">No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
import { logout } from "@/auth"

export default {
  name: 'AppBar',
  computed: {
    ...mapGetters(["getUser"])
  },
  data: function() {
    return {
      searchTerm: null,
      logoutDialog: false
    };
  },
  methods: {
    logout() {
      logout();
      location.reload();
    }
  }
}
</script>
<style scoped>
.secondary--background-color {
  background-color: var(--v-background-base);
}

.secondary--border-bottom::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #F5F5F5;
}
</style>