<template>
  <v-card color="elevation-0">
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <p class="mb-0 text-subtitle-2">
            {{ $t('details.selectTier') }}
          </p>
            
          <v-chip-group v-model="selectedTier">
            <v-chip
              v-for="(tier, index) in tiers"
              :key="`tier_${index}`"
              rounded
              small
              :class="tierChipClass(index)"
            >
              <span v-if="index == 0"> {{ $t('details.baseWheel') }}</span>
              <span v-else>Tier {{ index + 1 }}</span>
            </v-chip>
          </v-chip-group>
          <v-row>
            <v-divider class="mb-6 mt-6"></v-divider>
          </v-row>
          <v-row class="mb-2">
            <v-btn outlined rounded class="mr-4 black--text" @click="randomSpin" :style="disableSpinButtons">{{ $t('action.randomspin') }}</v-btn>
          </v-row>
          <v-row class="row mb-2">
            <v-radio-group row v-model="spinDirection">
                <v-radio label="Clockwise" value="clockwise"></v-radio>
                <v-radio label="Counter Clockwise" value="counterClockwise"></v-radio>
                <v-radio label="Alternate Direction" value="alternate"></v-radio>
            </v-radio-group>
          </v-row>
          <v-row>
            <v-btn outlined rounded class="mr-4 black--text" @click="rotate(-1)" :style="disableSpinButtons"><v-icon>rotate-left</v-icon>{{ $t('action.rotateLeft') }} </v-btn>
            <v-btn outlined rounded class="mr-4 black--text" @click="rotate(1)" :style="disableSpinButtons">{{ $t('action.rotateRight') }}<v-icon>rotate-right</v-icon></v-btn>
          </v-row>
          <v-row class="mb-2">
            <v-checkbox
              v-model="winningAnimationEnabled" label="Play winning animation on landing segment" color="primary"
            ></v-checkbox>
          </v-row>
          <v-row>
            <v-btn outlined rounded :style="disableSpinButtons" class="mr-4 black--text" @click="playIdleAnimation">{{ $t('details.idle_animation') }} </v-btn>
          </v-row>
        </v-col>
        <v-col cols="6">
          <div class="custom_wheel_container" data-test="wheel-container">
            <digi-wheel ref="digiwheel" :showFlapper="true" :showWinningAnimation="winningAnimationEnabled" :key="render" @wheelStopped="handleWheelStopped"/>
          </div>
        </v-col>

      </v-row>
    </v-container>
  </v-card>
  </template>
  
  <script>
  import DigiWheel from "./customWheel/DigiWheel.vue";
  import { mapGetters, mapActions  } from "vuex";

  export default {
    components: {
      DigiWheel
    },
    data(){
      return {
        render: 0,
        spinDirection: "clockwise",
        spinDirectionValue: 1,
        winningAnimationEnabled: true,
        wheelSpinning: false
      }
    },
    created() {
      this.selectedTier = 0;
      if (this.getSegments) {
        this.updateSelectedTier(this.selectedTier)
      }
    },
    computed: {
    ...mapGetters([
      "getSelectedGame",
    ]),
    selectedTier: {
      get() {
        return this.getSelectedGame.selectedTier;
      },
      set(index) {
        if (index == undefined) return;
        this.updateSelectedTier(index);
        this.selectedSegment = this.getSegments[0];
        this.loadWheel();
      },
    },
    tiers() {
      return this.getSelectedGame.tiers.length + 1;
    },
    getSegments() {
      return this.selectedTier == 0 ? this.getSelectedGame.segments : this.getSelectedGame.tiers[this.selectedTier -1].segments;
    },
    disableSpinButtons() {
      return this.wheelSpinning ? 'pointer-events: none; opacity: 0.6' : ''; 
    },
  },
    methods: {
      ...mapActions(["updateSelectedTier"]),

      randomSpin() {
        this.wheelSpinning = true;
        if (this.spinDirection == 'alternate') {
          this.spinDirectionValue = this.spinDirectionValue == 1 ? -1 : 1;
        } else {
          this.spinDirectionValue = this.spinDirection == 'clockwise' ? 1 : -1
        }
        this.$refs.digiwheel.spinWheel(this.spinDirectionValue, null);
      },
      rotate(direction) {
        this.wheelSpinning = true;
        let rotationAmount = 360 / this.getSegments.length;
        this.$refs.digiwheel.spinWheel(direction, rotationAmount);
      },
      playIdleAnimation() {
        this.$refs.digiwheel.playIdleAnimation();
      },
      tierChipClass(index) {
        return index == this.selectedTier ? 'primary' : ''
      },
      loadWheel() {
        this.render = !this.render;
      },
      handleWheelStopped() {
        setTimeout(() => {
          this.wheelSpinning = false;
        }, 3000);
        
      }
    }
  }
  </script>
  
  <style scoped>
  .custom_wheel_container {
      width: 30vw;
      height: 30vw;
      margin: auto;
      margin-bottom: 30px !important;
      overflow: hidden;
  }
  
  .header,
  .filters {
    max-width: 80%;
    margin: 0 auto;
  }
  
  .picker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  
    max-width: 90%;
    height: 40vw;
    margin: 16px auto 0;
    background-color: #f8f8fa;
    border-radius: 4px;
  
    text-align: center;
    font-size: 18px;
    color: #bbbcc9;
  }
  
  .picker:focus {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  }
  
  .picker_arrow {
    font-size: 18px;
    height: 5px;
    width: 24px;
    padding: 4px;
    margin: 0 auto;
  }
  
  .picker_arrow.bottom {
    transform: rotate(180deg);
  }
  
  .picker_chosen {
    background-color: #f8f8fa;
    border-top: 1px solid #dddde4;
    border-bottom: 1px solid #dddde4;
    color: #121212;
    font-size: 20px;
  }
  
  .filters {
    display: flex;
    flex-wrap: wrap;
  }
  
  .filters label {
    margin-top: 12px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .filters label span {
    margin-left: 8px;
  }
  
  @media (max-width: 768px) {
    .filters label {
      width: 100%;
    }
  }
  .btn-toggle {
    flex-direction: column;
  }
  
  .nonActiveChip {
    border: 1px solid black;
    border-radius: 1em;
  }
  </style>