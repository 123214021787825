<template>
  
  <v-card class="elevation-0">
    <div id="loadingOverlay" :style="loadingOverlayStyle">
        <div class="loader" :style="spinnerStyle"></div>
    </div>

    <v-card-title class="font-weight-normal">{{ $t('dashboard.title') }}</v-card-title>
    <v-card-subtitle class="font-weight-light">{{ $t('dashboard.subtitle') }}</v-card-subtitle>  
    
    <v-tabs v-model="tab" class="d-flex align-center align-end">
      <v-tab
        v-for="item in items"
        :key="item.tab"
        active-class="black--text"
        class="text-capitalize"
        @change="runFilters(item.tab)"
      >
        {{ item.tab }}
      </v-tab>

      <v-spacer></v-spacer>
      <v-btn class="black white--text ml-8 text-capitalize align-self-center" small to="/game/new"><v-icon class="mr-2" small>mdi-plus</v-icon>{{ $t("details.addNew")}}</v-btn>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
      >
        <v-card>
          <v-card-title class="mt-0 pt-0 pb-0 mb-0">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search Wheels by Name"
              single-line
              hide-details
              class="mt-0 pt-0 pb-0 mb-0"
            ></v-text-field>
            <v-select
              v-model="segmentNumberFilter"   
              :items="segments"
              class="selectWidth pt-5 ml-2"
              label="Number of Segments"
              outlined
              dense
            >
            </v-select>
            <v-select
              v-model="tiersNumberFilter"   
              :items="tiers"
              class="selectWidth pt-5 ml-2"
              label="Number of Tiers"
              outlined
              dense
            >
            </v-select>
          </v-card-title>

          <v-row>
            <v-col cols="12" class="mr-3 pt-0">
              <v-chip-group
              >
                <v-chip
                  v-for="(tag, index) in tags"
                  :key="`tag_${index}`"
                  rounded
                  small
                >
                  {{ tag }} 
                  <v-icon
                    small
                    class="ml-1"
                    @click.stop="removeTag(index)"
                  >
                    mdi-close
                  </v-icon>
                </v-chip>
                <v-btn
                  elevation="0"
                  small
                  rounded
                  class="mr-2 black--text float-right"
                  @click="addTagDialog = true"
                  ><v-icon>mdi-plus</v-icon> {{ $t("details.addTag")}}</v-btn
                >
                
                <v-btn 
                  class="red white--text ml-8 text-capitalize align-self-center" 
                  small 
                  @click="triggerDeleteConfirmation()"
                  :style="disableDeleteBtnStyle"
                  >
                  Delete
                </v-btn>
              </v-chip-group>
              
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="tableItems"
            :search="search"
            :loading="loading"
            show-select
            v-model="selectedItems"
            item-key="id"
            :items-per-page="10"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="item.status == 'Complete' ? 'green' : 'orange'"
                dark
                :class="item.status == 'Complete' ? 'pl-10' : 'pl-12'"
                :style="{ width: '130px' }"
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div style="display: flex;">
                <v-icon
                  small
                  class="mr-2"
                  :style="disableActionsStyle"
                  :class="item.tenantId == 'shared' && getTenant != 'shared' ? 'disabled' : ''"
                  @click="editGame(item.id)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  :style="disableActionsStyle"
                  @click="cloneGame(item)"
                >
                  mdi-content-copy
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  :style="disableActionsStyle"
                  @click="triggerDeleteConfirmation(item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon v-show="item.type == 'complete_game'"
                  small
                  @click="downloadGame(item)"
                  :style="disableActionsStyle"
                >
                  mdi-download
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline align-self-center text-center">{{ $t("details.confirmDelete")}}</v-card-title>
          <v-card-actions>
            <v-btn color="primary" text @click="deleteWheelGame">Yes</v-btn>
            <v-btn color="red" text @click="deleteDialog = false;">No</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="addTagDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ $t("details.newFilter")}}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="8"
                >
                  <v-text-field
                    v-model="tagFilter"
                    label="Tag Name"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="addTagDialog = false"
            >
              {{ $t("action.cancel")}}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="addTag"
            >
            {{ $t("action.add")}}
            </v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-card>
  
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from '../router'

export default {
  name: 'DashBoard',
  data () {
    return {
      tab: null,
      items: [
          { tab: 'All Wheels', content: 'Clear filter to show all wheels' },
          { tab: 'Drafts', content: 'Apply filter to show ones in draft' },
          { tab: 'Completed', content: 'Apply filter to show all wheels' },
        ],
      deleteDialog: false,
      addTagDialog: false,
      loading: true,
      tableItems: [],
      selectedItems: [],
      headers: [
          {
            text: 'Name',
            align: 'start',
            value: 'name',
            search: 'name'
          },
          { text: 'Date Created', value: 'dateCreated', filterable: false, sort: this.sortDate
          },
          { text: 'Segments', value: 'noOfSegments', filterable: false, },
          { text: 'Tiers', value: 'noOfTiers', filterable: false, },
          { text: 'Status', value: 'status', filterable: false, },
          { text: 'Tags', value: 'tags', filterable: false, },
          { text: 'Actions', value: 'actions', sortable: false },
      ],
      search: '',
      tags: [],
      tagFilter: '',
      segments: ["All", 3, 9, 18, 27, 54],
      tiers: ["All", 1, 2, 3, 4],
      segmentNumberFilter: 'All',
      tiersNumberFilter: 'All',
      downloading: false,
      deleteGame: null
    }
  },
  async created() {
    if (this.getGamesList != null && this.getGamesList.length > 0) return;
    await this.getAllWheels({tenantId: this.getTenant, update: 'addNewGameAction'});
    this.loading = false;
    this.tableItems = this.getGamesList.slice();
  },
  mounted() {
    if (this.getGamesList != null && this.getGamesList.length > 0) {
      this.loading = false;
    }
  },
  watch: {
    segmentNumberFilter() {
      this.runFilters();
    },
    tiersNumberFilter() {
      this.runFilters();
    },
  },
  methods: {
    ...mapActions(["deleteContent", "getAllWheels", "getGame", "downloadGameZip", "deleteContents", "cloneGameAction", "deleteWheelAssets"]),
    sortDate(a, b) {
      const aSplitDate = a.split('/');
      const bSplitDate = b.split('/');
      const aNewDate = aSplitDate[1] + '/' + aSplitDate[0] + '/' + aSplitDate[2];
      const bNewDate = bSplitDate[1] + '/' + bSplitDate[0] + '/' + bSplitDate[2];
      let aDate = new Date(aNewDate).getTime();
      let bDate = new Date(bNewDate).getTime();

      if (aDate > bDate) {
        return 1;
      } else if (aDate < bDate) {
        return -1;
      } else {
        return 0;
      }
    },
    addTag() {
      this.addTagDialog = false;
      if (this.tagFilter != null && this.tagFilter != '') {
        this.tags.push(this.tagFilter);
        this.tagFilter = "";
        this.runFilters();
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
      if (this.tags == null || this.tags.length == 0) {
        this.tableItems = this.getGamesList.slice();
      }
      this.runFilters();
    },
    runFilters(newTab) {
      const tab = newTab ? newTab : this.items[this.tab].tab;
      //Tabs
      this.tableItems = [];
      if (tab == "Completed") {
        this.getGamesList.forEach(game => {
          if (game.status == "Complete") this.tableItems.push(game);
        });
      } else if (tab == "Drafts") {
        this.getGamesList.forEach(game => {
          if (game.status == "Draft") this.tableItems.push(game);
        });
      } else {
        this.tableItems = this.getGamesList.slice();
      } 
      
      //Filter by segment number
      if (this.segmentNumberFilter != 'All') {
        let thiz = this;
        this.tableItems = this.tableItems.filter(function (game) {
          return game.noOfSegments == thiz.segmentNumberFilter;
        });
      }

      //Filter by tier number
      if (this.tiersNumberFilter != 'All') {
        let thiz = this;
        this.tableItems = this.tableItems.filter(function (game) {
          return game.noOfTiers == thiz.tiersNumberFilter;
        });
      }

      // let newTableItems = [];
      //Filter by tags
      // if (this.tags == null || this.tags.length == 0) {
      //   newTableItems = this.getGamesList.slice();
      // } else {
      //   this.tags.forEach(tag => {
      //     this.getGamesList.forEach( (game, index) => {
      //       if (game.tags && game.tags.includes(tag)) {
      //         newTableItems.push(game);
      //       }
      //     });
      //   });
      // }

    },
    showGame(game, item) {
      if (item.tab == "Completed" && game.type == 'draft_game') {
        return false;
      }
      if (item.tab == "Drafts" && !game.name == 'complete_game') {
        return false;
      }
      return true;
    },
    async editGame(id) {
      this.downloading = true;
      await this.getGame(id);
      this.downloading = false;
      router.push("/game/new");
    },
    async cloneGame(item) {
      this.downloading = true;
      // if (item.tenantId == "shared" && this.getTenant != 'shared') {
        
      // }
      await this.getGame(item.id);
      await this.cloneGameAction();
      this.downloading = false;
      router.push("/game/new");
    },
    async deleteWheelGame() {
      this.downloading = true;
      if (this.selectedItems.length > 0) {
        let ids = [];
        let groupIds = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          if (this.selectedItems[i].tenantId == "shared" && this.getTenant != 'shared') {
            this.$toast.error("Cannot delete standard wheels.");
            this.deleteDialog = false;
            this.downloading = false; 
            return;
          }
          if (this.selectedItems[i].status == 'Complete') {
            ids.push(this.selectedItems[i].groupId);
          }
          ids.push(this.selectedItems[i].id);
          groupIds.push(this.selectedItems[i].groupId);
        }
        await this.deleteContents(ids);
        await this.deleteWheelAssets(groupIds);
        this.downloading = false;
        location.reload();
        
      } else {
        if (this.deleteGame.tenantId == "shared" && this.getTenant != 'shared') {
          this.$toast.error("Cannot delete standard wheel.");
          this.deleteDialog = false;
          this.downloading = false;
          return;
        }
        await this.deleteContent(this.deleteGame.id);
        if (this.deleteGame.status == 'Complete') {
          await this.deleteContent(this.deleteGame.groupId);
        }
        await this.deleteWheelAssets([this.deleteGame.groupId]);
        this.downloading = false;
        location.reload();
      }
      
      this.deleteDialog = false;
    },
    triggerDeleteConfirmation(game) {
      this.deleteGame = game;
      this.deleteDialog = true;
    },
    async downloadGame(game) {
      this.downloading = true;
      await this.downloadGameZip(game.groupId);
      this.downloading = false;
    }
  },
  computed: {
    ...mapGetters(["getGamesList", "getTenant", "getGamesList"]),
    spinnerStyle() {
        return this.downloading ? 'pointer-events: none' : '';
    },
    loadingOverlayStyle() {
        return this.downloading ? '' : 'display: none';
    },
    disableActionsStyle() {
      return this.selectedItems.length > 0 ? 'pointer-events: none; opacity: 0.6' : ''; 
    },
    disableDeleteBtnStyle() {
      return this.selectedItems.length < 1 ? 'pointer-events: none; opacity: 0.6' : ''; 
    },
  }
}
</script>

<style scoped>
.gameItem {
  border: 1px solid black;
  border-radius: 1em;
}
.selectWidth {
  max-width: 310px;
}

#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.disabled {
  pointer-events: none;
  opacity: 0.6
}
</style>