export function isAuthenticated() {
    const token = localStorage.getItem("token");
  
    // Check if a token exists and if it's not expired.
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        const expirationTime = payload.exp * 1000;
        const currentTime = new Date().getTime();
  
        if (currentTime < expirationTime) {
          return true;
        }
      } catch (e) {
        console.error("Error parsing token:", e);
      }
    }
  
    return false;
  }

  export function logout(){
    localStorage.removeItem('token');
    localStorage.removeItem('tenant');
  }
  