<template>
    <div class="tab-container">
      <v-row class="tab-navigation ml-4 mb-2">
        <v-col auto
          v-for="(tab, index) in tabs"
          :key="index"
          :class="['tab', { active: index === currentTab }, { selected: index <= currentTab }]"
          @click="selectTab(index)"
          class="text-capitalize text-subtitle-2 text-left hover tabWidth"
        >
          <span :class="[{'font-weight-bold': index <= currentTab }]"> {{ $vuetify.breakpoint.lgAndUp ? 'Step ' : '' }} {{ tab.step }}: </span>
          <span class="mr-6 font-weight-light">{{ tab.title }}</span>
      </v-col>
      </v-row>

      <div class="tab-content">
        <slot :tab="tabs[currentTab]"></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ProgressTabs",
    props: {
      tabs: {
        type: Array,
        required: true,
        validator: (value) => value.every((tab) => typeof tab.title === 'string'),
      },
      initialTab: {
        type: Number,
        default: 0,
        validator: (value) => Number.isInteger(value),
      },
      completedSteps: {
        type: Number,
        default: 0,
        validator: (value) => Number.isInteger(value),
      },
    },
    data() {
      return {
        currentTab: this.initialTab,
      };
    },
    methods: {
      selectTab(index) {
        if (index !== this.currentTab && this.completedSteps >= index) {
          this.currentTab = index;
          this.$emit('updateCurrentStep', this.currentTab + 1);
        }
      },
      continueBtnPress(index) {
        this.currentTab = index;
      },
    },
  };
  </script>
  
  <style>
  .hover {
    cursor: pointer;
  }
  .tab-navigation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .tab {
    padding: 5px 10px;
    border-bottom: 2.5px solid #f5f5f5;
    color: grey
  }
  
  .tab.selected {
    color: black;
    border-bottom: 2.5px solid #ffb700;
  }
  
  .tab.active {
    color: black;
    border-bottom: 2.5px solid #ffb700;
  }
  
  .tab-content {
    padding: 10px;
    border-bottom: none;
    border-top: none;
  }
  .tabWidth {
    width: 23vw;
    text-wrap: nowrap;
  }
  </style>
  