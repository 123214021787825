import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/Dashboard'
import CreateWheel from '@/views/CreateWheel'
import LoginView from '@/views/LoginView'
import AssetLibrary from '@/views/AssetLibrary'

import { isAuthenticated } from "@/auth"

Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/game/:id',
    name: 'game',
    component: CreateWheel,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/assets',
    name: 'assets',
    component: AssetLibrary,
    meta: {
      requiresAuth: true,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    // Replace isAuthenticated() with your authentication logic
    if (!isAuthenticated()) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
