<template>
    <v-card class="elevation-0">
      <div id="loadingOverlay" :style="loadingOverlayStyle">
            <div class="loader" :style="spinnerStyle"></div>
        </div>
        <v-card-title class="font-weight-normal ml-2">{{ $t('dashboard.title') }}</v-card-title>
        <v-card-subtitle class="font-weight-light ml-2">{{ $t('dashboard.subtitle') }}</v-card-subtitle>  
        
        <progress-tabs ref="tabsRef" :tabs="tabs" @updateCurrentStep="setCurrentStep" :completedSteps="completedSteps">
            <template v-slot="{ tab }">
                <component ref="componentRef" :is="tab.component"/>

                <v-row class="d-flex mt-4">
                    <v-btn outlined class="mr-auto red white--text text-capitalize" data-test="cancel-btn" @click="triggerCancelConfirmation">{{ $t('action.cancel') }}</v-btn>
                    <v-btn v-if="getSelectedGame.status == 'Complete' && currentStep != 5" outlined class="mr-4 black--text text-capitalize" @click="saveWheelGame" data-test="save-btn">{{ $t('action.save') }}</v-btn>
                    <v-btn v-if="currentStep != 5" outlined  class="mr-4 black--text text-capitalize" @click="saveAsDraft" data-test="save-draft-btn">{{ $t('action.draft') }}</v-btn>
                    <v-btn v-if="showBackBtn" outlined class="mr-4 black--text text-capitalize" @click="backStep" data-test="continue-btn"><v-icon>mdi-chevron-left</v-icon>{{ $t('action.goback') }}</v-btn>
                    <v-btn v-if="showContinueBtn" outlined class="mr-4 black--text text-capitalize" @click="nextStep" data-test="continue-btn">{{ $t('action.continue') }}<v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-row>

                <v-dialog v-model="cancelDialog" max-width="500px">
                  <v-card>
                    <v-card-title class="headline align-self-center text-center">Are you sure you want to cancel?</v-card-title>
                    <v-card-text>The wheel building process will be abandoned and any unsaved changes will be discarded.</v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" text @click="cancel">Yes</v-btn>
                      <v-btn color="red" text @click="cancelDialog = false;">No</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
            </template>
        </progress-tabs>
    </v-card>
</template>

<script>
  import ProgressTabs from '@/components/structural/ProgressTabs'
  import WheelDetails from '@/components/WheelDetails'
  import CreateSegments from '@/components/CreateSegments'
  import BuildWheel from '@/components/BuildWheel'
  import PreviewWheel from '@/components/PreviewWheel'
  import VerifyWheel from '@/components/VerifyWheel'
  import { mapActions, mapGetters } from 'vuex';
  

  export default {
    data () {
      return {
        tab: null,
        tabs: [
          { step: '1', title: 'Wheel Details', component: WheelDetails },
          { step: '2', title: 'Create Segment', component: CreateSegments },
          { step: '3', title: 'Build Wheel', component: BuildWheel },
          { step: '4', title: 'Preview Wheel', component: PreviewWheel },
          { step: '5', title: 'Verify and submit', component: VerifyWheel },
        ],
        currentStep: 1,
        completedSteps: 0,
        cancelDialog: false,
        downloading: false
      }
    },
    components: {
        ProgressTabs,
        WheelDetails,
        CreateSegments,
        BuildWheel,
        PreviewWheel,
        VerifyWheel
    },
    async created() {
      if (this.getDropdownsLoaded) return;
      this.downloading = true;
      if (this.getGamesList.length == 0) {
        await this.getAllWheels({tenantId: this.getTenant, update: 'addNewGameAction'});
      }
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/content/idle`, tenantId: this.getTenant, update: 'updateIdleAnimationOptions'});
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/content/foreground`, tenantId: this.getTenant, update:"updateForegroundOptions"});
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/content/background`, tenantId: this.getTenant, update:"updateBackgroundOptions"});
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/${this.getSelectedGame.no_of_segments}`, tenantId: this.getTenant, update:"updateSegmentOptions"});
      await this.getContentByPrefixAndTenant({prefix:`${this.getTenant}/segment/winning-animation`, tenantId: this.getTenant, update:"updateWinningSegmentAnimations"})
      this.$refs.componentRef.loadAnimationOptions();
      this.updateDropdownsLoaded(true);
      this.downloading = false;
    },
    mounted() {
      window.addEventListener('beforeunload', function(event) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave?';
      });
    },
    computed: {
      ...mapGetters([
        "getSelectedGame", "getUploadContentError", "getGamesList", "getDropdownsLoaded", "getTenant"
      ]),
      showContinueBtn() {
        return this.currentStep == 5 ? false : true;
      },
      showBackBtn() {
        return this.currentStep == 1 ? false : true;
      },
      spinnerStyle() {
        return this.downloading ? 'pointer-events: none' : '';
      },
      loadingOverlayStyle() {
          return this.downloading ? '' : 'display: none';
      },
    },
    methods: {
      ...mapActions(["updateGameStage", "generateGameJSON", "saveGame", "generateGameJSON", "uploadWheelGame", "saveGame", "deleteContent", "updateDropdownsLoaded", 
      "getContentByPrefixAndTenant", "getAllWheels"]),

      async saveWheelGame() {
        if (this.getSelectedGame.name == null || this.getSelectedGame.description == null || this.getSelectedGame.name == '' || this.getSelectedGame.description == '') {
          this.$toast.error("Please enter a wheel name and description.");
          return;
        } else {
          for (let i = 0; i < this.getGamesList.length; i++) {
            if (this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase() && this.getGamesList[i].id != this.getSelectedGame.savedGameId) {
              this.$toast.error("Game name is already in use. Please try again.");
              return false;
            }
          }
        } 
        this.downloading = true;
        await this.generateGameJSON();
        await this.uploadWheelGame({'incompleteGame' : false});
        await this.saveGame('complete');
        this.downloading = false;
      },
      saveAsDraft() {
        if (this.getSelectedGame.name == null || this.getSelectedGame.description == null || this.getSelectedGame.name == '' || this.getSelectedGame.description == '') {
          this.$toast.error("Please enter a wheel name and description.");
          return;
        } else {
          for (let i = 0; i < this.getGamesList.length; i++) {
            if (this.getSelectedGame.status == "Complete" && this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase()) {
              this.$toast.error("Game name is already in use. Please try again.");
              return false;
            }
            if (this.getSelectedGame.status == "Draft" && this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase() && this.getGamesList[i].id != this.getSelectedGame.savedGameId) {
              this.$toast.error("Game name is already in use. Please try again.");
              return false;
            }
            if (this.getSelectedGame.status != "Complete" && this.getSelectedGame.status != "Draft" && this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase()) {
              this.$toast.error("Game name is already in use. Please try again.");
              return false;
            }
          }
          this.downloading = true;
          this.saveGame("draft")
            .then(() => {
              this.downloading = false;
              if (this.getUploadContentError) {
                this.$toast.error(this.getUploadContentError);
              }  
            });
        }
      },
      nextStep() {
        if (this.$refs.componentRef.$refs.form && !this.$refs.componentRef.$refs.form.validate()) {
          return;
        } else if (this.currentStep == 1 && !this.isStep1Valid()) {
          return;
        } else if (this.currentStep == 2 && !this.isStep2Valid()) {
          return;
        } else if (this.currentStep == 3 && !this.isStep3Valid()) {
          return;
        }

        if (this.currentStep == this.completedSteps + 1) {
          this.completedSteps++;
        }
        this.currentStep = this.currentStep + 1;
        this.updateAndReload();
      },
      backStep() {
        this.currentStep = this.currentStep - 1;
        this.updateAndReload();
      },
      isStep1Valid() {
        for (let i = 0; i < this.getGamesList.length; i++) {
          if (this.getSelectedGame.status == "Complete" && this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase() && this.getGamesList[i].id != this.getSelectedGame.savedGameId) {
            this.$toast.error("Game name is already in use. Please try again.");
            return false;
          }
          if (this.getSelectedGame.status == "Draft" && this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase() && this.getGamesList[i].id != this.getSelectedGame.savedGameId) {
            this.$toast.error("Game name is already in use. Please try again.");
            return false;
          }
          if (this.getSelectedGame.status != "Complete" && this.getSelectedGame.status != "Draft" && this.getGamesList[i].name.toLowerCase() == this.getSelectedGame.name.toLowerCase()) {
            this.$toast.error("Game name is already in use. Please try again.");
            return false;
          }
        }
        return true;
      },
      isStep2Valid() {
        let allTiersSegmentsSelected = true;
        for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
          if (this.getSelectedGame.tiers[i].segments == null || this.getSelectedGame.tiers[i].segments.length == 0) {
            allTiersSegmentsSelected = false;
          }
        }

        if (!this.$refs.componentRef.$refs.tierSetupForm.validate() || !this.$refs.componentRef.$refs.segmentForm.validate()) {
            this.$toast.error("Please fill in form fields correctly.");
            return false;
        }
        if (this.getSelectedGame.no_of_segments == null || this.getSelectedGame.no_of_segments == 0 || !allTiersSegmentsSelected) {
            this.$toast.error("Please select number of segments for all tiers.");
            return false;
        }

        let selectedSegmentImagesCount = 0;
        for (let i = 0; i < this.getSelectedGame.segment_definitions.length; i++) {
          if (this.getSelectedGame.segment_definitions[i].image.displayName != null ) {
            selectedSegmentImagesCount++;
          }
        }
        if (selectedSegmentImagesCount < 3) {
          this.$toast.error("Please select an image for at least 3 segments in each tier.");
          return false;
        }

        for (let t = 0; t < this.getSelectedGame.tiers.length; t++) {
          selectedSegmentImagesCount = 0;
          for (let i = 0; i < this.getSelectedGame.tiers[t].segment_definitions.length; i++) {
            if (this.getSelectedGame.tiers[t].segment_definitions[i].image.displayName != null) {
              selectedSegmentImagesCount++;
            }
          }
          if (selectedSegmentImagesCount < 3) {
            this.$toast.error("Please select an image for at least 3 segments in each tier.");
            return false;
          }
        }

        // Check tiers for at least one bonus segment
        if (this.getSelectedGame.tiers.length > 0) {
          let bonusSegmentExists = false;
          for (let i = 0; i < this.getSelectedGame.segment_definitions.length; i++) {
            if (this.getSelectedGame.segment_definitions[i].bonusSegment) {
              bonusSegmentExists = true;
              break;
            }
          }
          if (!bonusSegmentExists) {
            this.$toast.error("Please select at least one bonus segment for the base wheel.");
            return false;
          }
          
          if (this.getSelectedGame.tiers.length > 1) {
            for (let t = 0; t < this.getSelectedGame.tiers.length - 1; t++) { // Check all tiers for at least one bonus segment except for the last 
              let tierBonusSegmentExists = false;
              for (let i = 0; i < this.getSelectedGame.tiers[t].segment_definitions.length; i++) {
                if (this.getSelectedGame.tiers[t].segment_definitions[i].bonusSegment) {
                  tierBonusSegmentExists = true;
                  break;
                }
              }
              if (!tierBonusSegmentExists) {
                this.$toast.error(`Please select at least one bonus segment for tier ${t+2}.`);
                return false;
              }
            }
          }
        }

        return true;
      },
      isStep3Valid() {
        for (let i = 0; i < this.getSelectedGame.segments.length; i++) {
          if (this.getSelectedGame.segments[i].value == null || this.getSelectedGame.segments[i].value == "") {
            this.$toast.error("Please fill in all segments on the wheel");
            return false;
          }
        }
        if (this.getSelectedGame.tiers.length > 0) {
          for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
            if (this.getSelectedGame.tiers[i].segments[i] == null || this.getSelectedGame.tiers[i].segments[i].value == null || this.getSelectedGame.tiers[i].segments[i].value == "") {
              this.$toast.error("Please fill in all segments on all wheels");
              return false;
            }
          }
        }

        // Check tiers for at least one bonus segment
        if (this.getSelectedGame.tiers.length > 0) {
          let bonusSegmentExists = false;
          for (let i = 0; i < this.getSelectedGame.segments.length; i++) {
            if (this.getSelectedGame.segments[i].bonusSegment) {
              bonusSegmentExists = true;
              break;
            }
          }
          if (!bonusSegmentExists) {
            this.$toast.error("Please select at least one bonus segment for the base wheel.");
            return false;
          }
          
          if (this.getSelectedGame.tiers.length > 1) {
            for (let t = 0; t < this.getSelectedGame.tiers.length - 1; t++) { // Check all tiers for at least one bonus segment except for the last 
              let tierBonusSegmentExists = false;
              for (let i = 0; i < this.getSelectedGame.tiers[t].segments.length; i++) {
                if (this.getSelectedGame.tiers[t].segments[i].bonusSegment) {
                  tierBonusSegmentExists = true;
                  break;
                }
              }
              if (!tierBonusSegmentExists) {
                this.$toast.error(`Please select at least one bonus segment for tier ${t+2}.`);
                return false;
              }
            }
          }
        }

        for (let i = 0; i < this.getSelectedGame.segments.length; i++) {
          if (this.getSelectedGame.segments[i].image.src == null) {
            this.$toast.error("Please fill in all segments on the wheel");
            return false;
          }
        }
        if (this.getSelectedGame.tiers.length > 0) {
          for (let i = 0; i < this.getSelectedGame.tiers.length; i++) {
            for (let j = 0; j < this.getSelectedGame.tiers[i].segments.length; j++) {
              if (this.getSelectedGame.tiers[i].segments[j].image.src == null) {
                this.$toast.error("Please fill in all segments on the wheel");
                return false;
              }
            }
          }
        }

        return true;
      },
      updateAndReload() {
        this.generateGameJSON();
        
        this.updateGameStage(this.currentStep);
        // TODO - trigger reiteerating through the wheel segments selections to apply changes made to the segment definitions
        this.$refs.tabsRef.continueBtnPress(this.currentStep - 1);
        if(this.currentStep == 3) {
          this.$emit("reloadWheel");
        }
      },
      cancel () {
        if (this.getSelectedGame.savedGameId == null) {
          this.deleteContent(this.getSelectedGame.wheelGameId);
        }
        this.$router.push({ path: '/', replace: true });
        location.reload();
      },
      triggerCancelConfirmation() {
        this.cancelDialog = true;
      },
      setCurrentStep(index) {
        if (this.completedSteps + 1 < index) {
          return;
        }
        this.currentStep = index;
      },
    },
  }
  </script>
  <style scoped>
  .v-slide-group__prev--disabled {
    display: none !important;
  }
  
#loadingOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  </style>