import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import i18n from '../plugins/i18n'
import login from './login'
import { EMPTY_CONTENT_OBJECT, EMPTY_SEGMENT_OBJECT, randomPastelHex } from '@/utils/helper';

Vue.use(VueAxios, axios)

var headers = {
    "Content-Type": "multipart/form-data"
};

const state = { 
    gamesList: [],
    assetList: [],
    sharedAssetList: [],

    selectedGame: {
        wheelGameId: null,
        savedGameId: null,
        name: null,
        description: null,
        baseWheel: null,
        selectedTier: 0,
        animationSize: 100,
        
        //properties for rendering but not yet configurable via the UI
        drawMode: "code",
        lastManStanding: false,

        //Media Options to populate select dropdowns - on game selection trigger a retrieval of media options
        tierOptions: [],
        idleAnimationOptions: [],
        foregroundOptions: [],
        backgroundOptions: [],
        animationOptions: [],
        segmentOptions: [],
        winningAnimationsOptions: [],

        //Media set via UI
        idle: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            delay: "50", //default value
            duration: "20"
        },
        animation: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            startupAsset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            startupAnimationDuration: "50",
            startupAnimationDelay: "5",
            startupAnimationLoop: false,
            winningAnimationDuration: "5"
        },
        background: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50"
        },
        backgroundOverlay: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50"
        },
        wheelOverlay: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50"
        },
        foreground: {
            asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
            assetSize: "50"
        },

        //Segments
        no_of_segments: null,
        segment_definitions:[
            {
                id: 0,
                name: "Segment 1",
                bonusSegment: false,
                image: {
                    id: 0,
                    file: null,
                    base64: null,
                    contentId: null
                },
                animation: {
                    id: 0,
                    file: null,
                    base64: null,
                    contentId: null
                },
                fillStyle: randomPastelHex()
            },
            {
                id: 1,
                name: "Segment 2",
                bonusSegment: false,
                image: {
                    id: 1,
                    file: null,
                    base64: null,
                    contentId: null
                },
                animation: {
                    id: 1,
                    file: null,
                    base64: null,
                    contentId: null
                },
                fillStyle: randomPastelHex()
            },
            {
                id: 2,
                name: "Segment 3",
                bonusSegment: false,
                image: {
                    id: 2,
                    file: null,
                    base64: null,
                    contentId: null
                },
                animation: {
                    id: 2,
                    file: null,
                    base64: null,
                    contentId: null
                },
                fillStyle: randomPastelHex(),
            }
        ],
        segments: [],
        
        //Tiers - after base wheel add all tiers here 
        tiers: []
    },

    gameJSON: {},
    
    currentStage: 1,
    uploadContentError: null,
    dropdownsLoaded: false
};

const getters = { 
    getGamesList: state => state.gamesList,
    getSelectedGame: state => state.selectedGame,
    getSelectedTier: state => state.selectedGame.selectedTier,
    getIdleAnimationOptions: state => state.selectedGame.idleAnimationOptions,
    getForegroundOptions: state => state.selectedGame.foregroundOptions,
    getBackgroundOptions: state => state.selectedGame.backgroundOptions,
    getTierOptions: state => state.selectedGame.tierOptions,
    getSegmentOptions: state => state.selectedGame.segmentOptions,
    getWinningAnimationsOptions: state => state.selectedGame.winningAnimationsOptions,
    getSegmentDefinitions: state => state.selectedGame.segment_definitions,
    getGameJSON: state => state.gameJSON,
    getCurrentStage: state => state.currentStage,
    getUploadContentError: state => state.uploadContentError,
    getDropdownsLoaded: state => state.dropdownsLoaded,
    getAssetList: state => state.assetList,
    getSharedAssetList: state => state.sharedAssetList,
};

const actions = {
    async deleteContent({commit}, id) {
        let response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/removeContentById?id=${id}`
        ).catch( error => {
            console.log(error);
        });
    },
    async deleteContents({commit}, ids) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/removeContentByIdInList`, ids)
        .catch( error => {
            console.log(error);
        });
    },
    async deleteWheelAssets({commit}, wheelIds) {
        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/removeAssociatedWheelAssets`, wheelIds)
        .catch( error => {
            console.log(error);
        });
    },
    async updateGameName({commit}, gameName) {
        commit("setGameName", gameName);
    },
    async updateGameDescription({commit}, gameDescription){
        commit("setGameDescription", gameDescription);
    },
    async updateIdleAnimation({commit}, idleAnimation) {
        commit("setIdleAnimation", idleAnimation);
    },
    async updateIdleAnimationTime({commit}, idleAnimationTime) {
        commit("setIdleAnimationTime", idleAnimationTime);
    },
    async updateIdleAnimationDuration({commit}, idleAnimationDuration) {
        commit("setIdleAnimationDuration", idleAnimationDuration);
    },
    async updateStartupAnimation({commit}, animation) {
        commit("setStartupAnimation", animation);
    },
    async updateStartupAnimationTime({commit}, animationTime) {
        commit("setStartupAnimationTime", animationTime);
    },
    async updateStartupAnimationDuration({commit}, animationDuration) {
        commit("setStartupAnimationDuration", animationDuration);
    },
    async updateStartupAnimationLoop({commit}, flag) {
        commit("setStartupAnimationLoop", flag);
    },
    async updateIdleAnimationOptions({commit}, options){
        commit("setIdleAnimationOptions", options)
    },
    async updateForegroundOptions({commit}, options){
        commit("setForegroundOptions", options)
    },
    async updateBackgroundOptions({commit}, options){
        commit("setBackgroundOptions", options)
    },
    async updateSelectedTier({commit}, selectedTier){
        commit("setSelectedTier", selectedTier)
    },
    async updateTierOptions({commit}, tierOptions){
        commit("setTierOptions", tierOptions)
    },
    async updateSegmentOptions({commit}, segmentImages){
        commit("setSegmentOptions", segmentImages)
    },
    async updateWinningSegmentAnimations({commit}, winningSegmentAnimationOptions){
        commit("setWinningAnimationsOptions", winningSegmentAnimationOptions)
    },
    async updateDropdownsLoaded({commit}, flag) {
        commit("setDropdownsLoaded", flag);
    },
    async updateNumberOfTiers({commit}, noOfTiers){
        var tiers = [];

        if (state.selectedGame.tiers.length + 1 >= noOfTiers) {
            for (let i = 0; i < noOfTiers - 1; i++) {
                tiers.push(state.selectedGame.tiers[i]);
            }
        } else {
            // Tier 0 is basewheel which already has the properties added so we start count from 1 
            for (let i = 0; i < state.selectedGame.tiers.length; i++) {
                tiers.push(state.selectedGame.tiers[i]);
            }
            for (let i = state.selectedGame.tiers.length; i < noOfTiers -1 ; i++) {
                tiers.push({
                    name: state.selectedGame.name + "_tier_" + Number(i+2),
                    nextRoundWheel: noOfTiers > i + 2 ? state.selectedGame.name + "_tier_" + Number(i+3) : null, 
                    baseWheel: state.selectedGame.name,
                    animationSize: "100",
                    idle: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        delay: "50",
                        duration: "20" 
                    },
                    animation: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        winningAnimationDuration: "5"
                    },
                    background: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50"
                    },
                    backgroundOverlay: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50"
                    },
                    wheelOverlay: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50"
                    },
                    foreground: {
                        asset: Object.assign({}, EMPTY_CONTENT_OBJECT),
                        assetSize: "50"
                    },
                    no_of_segments: 0,
                    segment_definitions: [
                        {
                            id: 0,
                            name: "Segment 1",
                            bonusSegment: false,
                            image: {
                                id: 0,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            animation: {
                                id: 0,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            fillStyle: randomPastelHex()
                        },
                        {
                            id: 1,
                            name: "Segment 2",
                            bonusSegment: false,
                            image: {
                                id: 1,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            animation: {
                                id: 1,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            fillStyle: randomPastelHex()
                        },
                        {
                            id: 2,
                            name: "Segment 3",
                            bonusSegment: false,
                            image: {
                                id: 2,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            animation: {
                                id: 2,
                                file: null,
                                base64: null,
                                contentId: null
                            },
                            fillStyle: randomPastelHex(),
                        }
                    ],
                    segments: []
                });
            }
        }

        state.selectedGame.nextRoundWheel = tiers[0] ? tiers[0].name : null;

        for (let i = 0; i < tiers.length; i++) {
            tiers[i].nextRoundWheel = tiers[i+1] ? tiers[i+1].name : null;
            tiers[i].baseWheel = state.selectedGame.name;
        }

        commit("setTiers", tiers);
        commit("resetBaseGameBonusSegments");
    },
    async updateTierInfo({commit}, payload){
        commit("setTierInfo", payload);
    },
    async updateSegmentChoice({commit}, payload){
        // TODO - CLEAR ALL Segment Defintions as well as centre conten, centre content size and ainning animation size for that tier
        commit("clearSegmentOptions")

        var segments = [];

        for (let i = 0; i < payload.noOfSegments; i++) {
            segments.push(Object.assign({}, EMPTY_SEGMENT_OBJECT));
        }
        let idCount = 0;
        for (let i = 0; i < segments.length; i++) {
            segments[i].id = idCount;
            idCount++;
        }
        
        commit("setSegmentsForTier", {segments: segments, tier: payload.tierIndex});
        commit("setSegmentChoice", {noOfSegments: payload.noOfSegments, tier: payload.tierIndex});
    },
    async updateSegmentImage({commit}, segmentImage) {
        commit("setSegmentImage", segmentImage);
    },
    async updateSegmentAnimation({commit}, segmentAnimation) {
        commit("setSegmentAnimation", segmentAnimation);
    },
    async updateForegroundContent({commit}, foregroundContent) {
        commit("setForegroundContent", foregroundContent);
    },
    async updateForegroundContentSize({commit}, foregroundContentSize) {
        commit("setForegroundContentSize", foregroundContentSize);
    },
    async updateBackgroundContent({commit}, backgroundContent) {
        commit("setBackgroundContent", backgroundContent);
    },
    async updateBackgroundContentSize({commit}, backgroundContentSize) {
        commit("setBackgroundContentSize", backgroundContentSize);
    },
    async updateWheelOverlayContent({commit}, wheelOverlayContent) {
        commit("setWheelOverlayContent", wheelOverlayContent);
    },
    async updateWheelOverlayContentSize({commit}, wheelOverlayContentSize) {
        commit("setWheelOverlayContentSize", wheelOverlayContentSize);
    },
    async updateBackgroundOverlayContent({commit}, backgroundOverlayContent) {
        commit("setBackgroundOverlayContent", backgroundOverlayContent);
    },
    async updateBackgroundOverlayContentSize({commit}, backgroundOverlayContentSize) {
        commit("setBackgroundOverlayContentSize", backgroundOverlayContentSize);
    },
    async updateWinningAnimationSize({commit}, winningAnimationSize) {
        commit("setWinningAnimationSize", winningAnimationSize);
    },
    async updateWinningAnimationDuration({commit}, winningAnimationDuration) {
        commit("setWinningAnimationDuration", winningAnimationDuration);
    },
    async updateWinningAnimationDelay({commit}, winningAnimationDelay) {
        commit("setWinningAnimationDelay", winningAnimationDelay);
    },
    async updateForegroundSrc({commit}, src) {
        commit("setForegroundSrc", src);
    },
    async updateBackgroundSrc({commit}, src) {
        commit("setBackgroundSrc", src);
    },
    async updateWheelOverlaySrc({commit}, src) {
        commit("setWheelOverlaySrc", src);
    },
    async updateBackgroundOverlaySrc({commit}, src) {
        commit("setBackgroundOverlaySrc", src);
    },
    async updateIdleAnimationSrc({commit}, src) {
        commit("setIdleAnimationSrc", src);
    },
    async updateStartupAnimationSrc({commit}, src) {
        commit("setStartupAnimationSrc", src);
    },
    async addNewGameAction({commit}, game) {
        commit("addNewGame", game);
    },
    async addNewTempGameAction({commit}, game) {
        commit("addNewTempGame", game);
    },
    async addNewAsset({commit}, asset) {
        commit("addNewAsset", asset);
    },
    async formatAssetList({commit}) {
        commit("formatAssetList");
    },
    async formatSharedAssetList({commit}) {
        commit("formatSharedAssetList");
    },
    async uploadContent({commit}, payload) {
        commit('setUploadContentError', null);
        headers.tenantId = login.state.tenant;
        let data = payload.file.type.includes('image') ? payload.base64 : null;

        let formData = new FormData();
        formData.append("data", data);
        formData.append("type", payload.type); 
        formData.append("prefix", payload.prefix);
        formData.append("mimeType", payload.file.type);
        formData.append("fileName", payload.file.name);
        formData.append("displayName", payload.displayName);
        formData.append("tenantId", login.state.tenant);
        formData.append("groupId", state.selectedGame.wheelGameId); 
        formData.append("file", payload.file);

        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadContent`, formData, { headers: headers }
        ).then(response => {
            let src = payload.file.type.includes('image') ? payload.base64 : URL.createObjectURL(payload.file);
            if (payload.commitMethod) {
                commit(payload.commitMethod, {
                    prefix: payload.prefix,
                    file: payload.file,
                    displayName: payload.file.name,
                    contentId: response.data.id,
                    index: payload.index,
                    src: src,
                    mimeType: payload.file.type
                });
            }
        }).catch( error => {
            console.log(error);
            commit('setUploadContentError', error.message ? error.message : error.code);
        });
    },
    async getContentByPrefixAndTenant({ dispatch }, payload){
        let result = null
        let indexOfForwardSlash = payload.prefix.indexOf('/');
        let prefixWithoutTenant = indexOfForwardSlash !== -1 ? payload.prefix.substring(indexOfForwardSlash + 1) : payload.prefix;
        let response = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/listByPrefixAndTenantIds?prefix=${prefixWithoutTenant}`,  [payload.tenantId, "shared"])
        .catch( error => {
            console.log(error);
        });
        result = response.data;
        dispatch(payload.update, result);
    },
    async getAllWheels({ dispatch }, payload){
        let wheels = null
        let response = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/listContentByTenantsAndContentTypes`, {'tenantIds': [payload.tenantId, "shared"], 'contentTypes': ["complete_game", "draft_game"]})
        .catch( error => {
            console.log(error);
        });
        if (!response) return; 
        wheels = response.data;
        for (let i in wheels){
            if (wheels[i].fileName == "null.json") continue;
            if (wheels[i].type != "complete_game" && wheels[i].type != "draft_game") continue;

            let dateObj = new Date(wheels[i].dateCreated);
            let dateCreated = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear().toString().substr(2, 2);
            wheels[i].dateCreated = dateCreated;
            wheels[i].noOfTiers = Number(wheels[i].prefix.split('_tier')[0].split('/')[1]);
            wheels[i].noOfSegments = Number(wheels[i].prefix.split('_segment')[0].split('/')[2]);
            wheels[i].noOfSegments = isNaN(wheels[i].noOfSegments) ? '-' : wheels[i].noOfSegments;
            wheels[i].status = wheels[i].type == "complete_game" ? "Complete" : "Draft";
            wheels[i].name = wheels[i].displayName;
            dispatch(payload.update, wheels[i]);
        }
    },
    async getAssets({commit}){
        let response = await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/listAssetsByTenants`,  [login.state.tenant, "shared"])
        .catch( error => {
            console.log(error);
        });
        commit("formatAssetList", response.data);
        
    },
    async getImageContentByIds() {
        let contentIds = [];
        for (let i = 0; i < state.selectedGame.segment_definitions.length; i++) {
            if (state.selectedGame.segment_definitions[i].image.contentId && state.selectedGame.segment_definitions[i].image.src == null
                 && state.selectedGame.segment_definitions[i].image.mimeType.includes("image")) {
                contentIds.push(state.selectedGame.segment_definitions[i].image.contentId);
            }
        }
        for (let i = 0; i < state.selectedGame.segments.length; i++) {
            if (state.selectedGame.segments[i].image.contentId && state.selectedGame.segments[i].image.src == null) {
                contentIds.push(state.selectedGame.segments[i].image.contentId);
            }
        }
        if (state.selectedGame.foreground.asset.contentId && state.selectedGame.foreground.asset.src == null && state.selectedGame.foreground.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.foreground.asset.contentId);
        }
        if (state.selectedGame.background.asset.contentId && state.selectedGame.background.asset.src == null && state.selectedGame.background.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.background.asset.contentId);
        }
        if (state.selectedGame.backgroundOverlay.asset.contentId && state.selectedGame.backgroundOverlay.asset.src == null && state.selectedGame.backgroundOverlay.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.backgroundOverlay.asset.contentId);
        }
        if (state.selectedGame.wheelOverlay.asset.contentId && state.selectedGame.wheelOverlay.asset.src == null && state.selectedGame.wheelOverlay.asset.mimeType.includes("image")) {
            contentIds.push(state.selectedGame.wheelOverlay.asset.contentId);
        }

        if (state.selectedGame.tiers.length > 0) {
            for (let i = 0; i < state.selectedGame.tiers.length; i++) {
                for (let s = 0; s < state.selectedGame.tiers[i].segment_definitions.length; s++) {
                    if (state.selectedGame.tiers[i].segment_definitions[s].image.contentId && state.selectedGame.tiers[i].segment_definitions[s].image.src == null
                        && state.selectedGame.tiers[i].segment_definitions[s].image.mimeType.includes("image")) {
                        contentIds.push(state.selectedGame.tiers[i].segment_definitions[s].image.contentId);
                    }
                }
                for (let s = 0; s < state.selectedGame.tiers[i].segments.length; s++) {
                    if (state.selectedGame.tiers[i].segments[s].image.contentId && state.selectedGame.tiers[i].segments[s].image.src == null) {
                        contentIds.push(state.selectedGame.tiers[i].segments[s].image.contentId);
                    }
                }
                if (state.selectedGame.tiers[i].foreground.asset.contentId && state.selectedGame.tiers[i].foreground.asset.src == null && state.selectedGame.tiers[i].foreground.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].foreground.asset.contentId);
                }
                if (state.selectedGame.tiers[i].background.asset.contentId && state.selectedGame.tiers[i].background.asset.src == null && state.selectedGame.tiers[i].background.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].background.asset.contentId);
                }
                if (state.selectedGame.tiers[i].backgroundOverlay.asset.contentId && state.selectedGame.tiers[i].backgroundOverlay.asset.src == null && state.selectedGame.tiers[i].backgroundOverlay.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].backgroundOverlay.asset.contentId);
                }
                if (state.selectedGame.tiers[i].wheelOverlay.asset.contentId && state.selectedGame.tiers[i].wheelOverlay.asset.src == null && state.selectedGame.tiers[i].wheelOverlay.asset.mimeType.includes("image")) {
                    contentIds.push(state.selectedGame.tiers[i].wheelOverlay.asset.contentId);
                }
            }
        }

        if (contentIds != null && contentIds.length > 0) {
            return axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentByIds`, contentIds);
        }
    },
    async getVideoContentById({_context }, id) {
        return axios({
            method: 'get',
            url: `${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getVideoContentFileById?id=${id}`,
            responseType: 'blob', // Set responseType to 'blob' to handle binary data
          })
    },
    async updateImageContentSrc({ commit }, data){
        commit("setImageContentSrc", data);
        
    },
    async fillNullSegments({ commit }){
        commit("fillNullSegments");
    },
    async updateGameStage({ commit }, stage) {
        commit("setStage", stage);
    },
    async addNewSegmentDefinition({commit}, payload){
        commit("addSegmentDefinition", payload);
    },
    async updateSegmentDefinitionName({commit}, payload) {
        commit("setSegmentDefinitionName", payload);
    },
    async updateSegmentDefinitionText({commit}, payload) {
        commit("setSegmentDefinitionText", payload);
    },
    async updateSegmentDefinitionBonus({commit}, payload) {
        commit("setSegmentDefinitionBonus", payload);
    },
    async deleteSegmentDefinition({commit}, payload) {
        commit("removeSegmentDefinition", payload);
    },
    async updateOneSegment({commit}, segmentInfo) {
        commit("setGameSegment", segmentInfo);
    },
    async generateGameJSON({commit}){
        var gameJSON = [];

        //format basewheel first
        var baseWheelSegments = [];
        state.selectedGame.segments.forEach(segment => {
            baseWheelSegments.push({
                id: segment.id,
                value: segment.value,
                text: segment.text,
                bonusSegment: segment.bonusSegment || false,
                image: segment.image && segment.image.contentId ? { contentId: segment.image.contentId, name: segment.image.displayName} : null,
                animation: segment.animation && segment.animation.contentId ? { contentId: segment.animation.contentId, name: segment.animation.displayName}  : null,
            });
        });

        var baseWheelInfo = {
            name: state.selectedGame.name,
            nextRoundWheel: state.selectedGame.tiers && state.selectedGame.tiers[0] ? 'tier_games/' + state.selectedGame.tiers[0].name : null,
            drawMode: "code",
            animationSize: state.selectedGame.animationSize,
            background : {
                asset: state.selectedGame.background && state.selectedGame.background.asset && state.selectedGame.background.asset.displayName ? { contentId: state.selectedGame.background.asset.contentId, name: state.selectedGame.background.asset.displayName} : null,
                assetSize: state.selectedGame.background ? state.selectedGame.background.assetSize : null,
                rotation: 'counter'
            },
            backgroundOverlay : {
                asset: state.selectedGame.backgroundOverlay && state.selectedGame.backgroundOverlay.asset && state.selectedGame.backgroundOverlay.asset.displayName ? { contentId: state.selectedGame.backgroundOverlay.asset.contentId, name: state.selectedGame.backgroundOverlay.asset.displayName} : null,
                assetSize: state.selectedGame.backgroundOverlay ? state.selectedGame.backgroundOverlay.assetSize : null,
                rotation: 'none'
            },
            wheelOverlay : {
                asset: state.selectedGame.wheelOverlay && state.selectedGame.wheelOverlay.asset && state.selectedGame.wheelOverlay.asset.displayName ? { contentId: state.selectedGame.wheelOverlay.asset.contentId, name: state.selectedGame.wheelOverlay.asset.displayName}: null,
                assetSize: state.selectedGame.wheelOverlay ? state.selectedGame.wheelOverlay.assetSize : null,
                rotation: 'none'
            },
            foreground: {
                asset: state.selectedGame.foreground && state.selectedGame.foreground.asset && state.selectedGame.foreground.asset.displayName ? { contentId: state.selectedGame.foreground.asset.contentId, name: state.selectedGame.foreground.asset.displayName} : null,
                assetSize: state.selectedGame.foreground ? state.selectedGame.foreground.assetSize : null,
                rotation: 'counter'
            },
            idle: {
                asset: state.selectedGame.idle && state.selectedGame.idle.asset && state.selectedGame.idle.asset.displayName ? { contentId: state.selectedGame.idle.asset.contentId, name: state.selectedGame.idle.asset.displayName} : null,
                delay: state.selectedGame.idle ? state.selectedGame.idle.delay : null,
                duration: state.selectedGame.idle ? state.selectedGame.idle.duration : null
            },
            animation: {
                winningAnimationDelay: state.selectedGame.animation ? state.selectedGame.animation.winningAnimationDelay : null,
                asset: state.selectedGame.animation && state.selectedGame.animation.asset && state.selectedGame.animation.asset.displayName ? state.selectedGame.animation.asset.displayName: null,
                winningAnimationDuration: state.selectedGame.animation && state.selectedGame.animation.winningAnimationDuration ? state.selectedGame.animation.winningAnimationDuration: null,
                startupAsset: state.selectedGame.animation && state.selectedGame.animation.startupAsset ? state.selectedGame.animation.startupAsset: null,
                startupAnimationDuration: state.selectedGame.animation && state.selectedGame.animation.startupAnimationDuration ? state.selectedGame.animation.startupAnimationDuration: null,
                startupAnimationDelay: state.selectedGame.animation && state.selectedGame.animation.startupAnimationDelay ? state.selectedGame.animation.startupAnimationDelay: null,
                startupAnimationLoop: state.selectedGame.animation && state.selectedGame.animation.startupAnimationLoop ? state.selectedGame.animation.startupAnimationLoop: null
            },
            segments: baseWheelSegments
        }

        // Add Formatted Base Wheel to the game
        gameJSON.push(baseWheelInfo);

        // iterate over tiers and format
        state.selectedGame.tiers.forEach(tier => {
            
            var tierSegments = [];
            tier.segments.forEach(segment => {
                tierSegments.push({
                    id: segment.id,
                    value: segment.value,
                    text: segment.text,
                    bonusSegment: segment.bonusSegment || false,
                    image: segment.image && segment.image.contentId ? { contentId: segment.image.contentId, name: segment.image.displayName} : null,
                    animation: segment.animation && segment.animation.contentId ? { contentId: segment.animation.contentId, name: segment.animation.displayName}  : null,
                });
            });

            var tierInfo = {
                name: tier.name,
                baseWheel: tier.baseWheel,
                drawMode: "code",
                animationSize: tier.animationSize,
                background : {
                    asset: tier.background && tier.background.asset && tier.background.asset.displayName ? { contentId: tier.background.asset.contentId, name: tier.background.asset.displayName} : null,
                    assetSize: tier.background ? tier.background.assetSize : null,
                    rotation: 'counter'
                },
                backgroundOverlay : {
                    asset: tier.backgroundOverlay && tier.backgroundOverlay.asset && tier.backgroundOverlay.asset.displayName ? { contentId: tier.backgroundOverlay.asset.contentId, name: tier.backgroundOverlay.asset.displayName } : null,
                    assetSize: tier.backgroundOverlay ? tier.backgroundOverlay.assetSize : null,
                    rotation: 'none'
                },
                wheelOverlay : {
                    asset: tier.wheelOverlay && tier.wheelOverlay.asset && tier.wheelOverlay.asset.displayName ? { contentId: tier.wheelOverlay.asset.contentId, name: tier.wheelOverlay.asset.displayName } : null,
                    assetSize: tier.wheelOverlay ? tier.wheelOverlay.assetSize : null,
                    rotation: 'none'
                },
                foreground: {
                    asset: tier.foreground && tier.foreground.asset && tier.foreground.asset.displayName ? { contentId: tier.foreground.asset.contentId, name: tier.foreground.asset.displayName } : null,
                    assetSize: tier.foreground ? tier.foreground.assetSize : null,
                    rotation: 'counter'
                },
                idle: {
                    asset: tier.idle && tier.idle.asset && tier.idle.asset.displayName ? { contentId: tier.idle.asset.contentId, name: tier.idle.asset.displayName } : null,
                    delay: tier.idle ? tier.idle.delay : null,
                    duration: tier.idle ? tier.duration : null
                },
                animation: {
                    winningAnimationDelay: tier.animation ? tier.animation.winningAnimationDelay : null,
                    asset: tier.animation && tier.animation.asset && tier.animation.asset.displayName ? state.selectedGame.animation.asset.displayName: null,
                    winningAnimationDuration: tier.animation && tier.animation.winningAnimationDuration ? tier.animation.winningAnimationDuration: null,
                },
                segments: tierSegments
            }

            if (tier.nextRoundWheel != null) {
                tierInfo.nextRoundWheel = 'tier_games/' + tier.nextRoundWheel;
            }
            //Add Formatted Tier to the game
            gameJSON.push(tierInfo);
        });

        commit("setGameJSON", gameJSON);
    },
    async uploadWheelGame({commit}, payload){
        const data = JSON.stringify(state.gameJSON);
        const jsonFile = new Blob([data], { type: 'application/json' });
        headers.tenantId = login.getters.getTenant();
        let noOfTiers = state.selectedGame.tiers == 0 ? 1 : state.selectedGame.tiers.length + 1;

        let formData = new FormData();
        formData.append("data", "data");
        formData.append("type", payload.incompleteGame ? "incomplete_game" : "game"); 
        formData.append("prefix", headers.tenantId + "/" + noOfTiers + "_tier/" + state.selectedGame.no_of_segments + "_segment");
        formData.append("mimeType", "application/json");
        formData.append("fileName", state.selectedGame.name + ".json");
        formData.append("displayName", state.selectedGame.name);
        formData.append("tenantId", login.getters.getTenant());
        formData.append("id", state.selectedGame.wheelGameId); //If game is being edited, send the original gameId instead of the draft/complete game JSON id
        formData.append("file", jsonFile);

        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadContent`, formData, { headers: headers })
            .then(response => {
                commit("setWheelGameId", response.data.id);
            })
            .catch(error => {
                console.log(error);
            });
    },
    async downloadGameZip({commit}, id){
        try {
            const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getGameById?id=${id}`,{ headers: headers, responseType: 'blob'});
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'game.zip');
            document.body.appendChild(link);
            link.click();
          } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
          }
    },
    async downloadContent({commit}, asset){
        try {

            if (asset.mimeType.includes('image')) {
                const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentById?id=${asset.id}`,{ headers: headers, responseType: 'blob'});

                const reader = new FileReader();
                reader.onload = function(event) {
                    try {
                        // Parse the JSON data
                        const jsonData = JSON.parse(event.target.result);
                        let blob;
                        if (jsonData.mimeType.includes("image")) {
                            const binaryImageData = atob(jsonData.data);
                            const uint8Array = new Uint8Array(binaryImageData.length);
                            for (let i = 0; i < binaryImageData.length; i++) {
                                uint8Array[i] = binaryImageData.charCodeAt(i);
                            }
                            blob = new Blob([uint8Array], { type: jsonData.mimeType });
                        } else if (jsonData.mimeType.includes("video")) {
                            
                        }
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', asset.fileName);
                        document.body.appendChild(link);
                        link.click();
                    } catch (error) {
                        console.error('Error parsing JSON:', error);
                    }
                };
                reader.readAsText(new Blob([response.data]));
            } else if (asset.mimeType.includes('video')) {
                const response = await axios({
                    method: 'get',
                    url: `${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getVideoContentFileById?id=${asset.id}`,
                    responseType: 'blob',
                });
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', asset.fileName);
                document.body.appendChild(link);
                link.click();
            }

          } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
          }
    },
    async getGame({commit}, id){
        try {
            const response = await axios.get(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/getContentById?id=${id}`)
            .catch( error => {
                console.log(error);
            });
            if (!response.data ||  !response.data.data || response.data.data.length < 100 ) return;
            const contentDto = response.data;
            const gameJsonString = atob(contentDto.data);
            try {
                const gameJsonObject = JSON.parse(gameJsonString);
                gameJsonObject.savedGameId = contentDto.id;
                gameJsonObject.type = contentDto.type;
                gameJsonObject.status = contentDto.type == "complete_game" ? "Complete" : "Draft";
                let dateObj = new Date(contentDto.dateCreated);
                let dateCreated = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear().toString().substr(2, 2);
                gameJsonObject.dateCreated = dateCreated;
                
                for (let i = 0; i < gameJsonObject.segment_definitions.length; i++) {
                    gameJsonObject.segment_definitions[i].name = gameJsonObject.segment_definitions[i].name.replace(/[^A-Za-z0-9\s_\-@#!.'"\$€£¥]/g, '');
                }
                for (let i = 0; i < gameJsonObject.tiers.length; i++) { 
                    for (let j = 0; j < gameJsonObject.tiers[i].segment_definitions.length; j++) {
                        gameJsonObject.tiers[i].segment_definitions[j].name = gameJsonObject.tiers[i].segment_definitions[j].name.replace(/[^A-Za-z0-9\s_\-@#!.'"\$€£¥]/g, '');
                    }
                }
                
                commit("setGame", gameJsonObject);
            } catch (error) {
                console.error("Error parsing JSON:", error);
            }
          } catch (error) {
            console.log(error);
            commit('setUploadContentError', error.message);
          }
    },
    async cloneGameAction({commit}){
        commit("cloneGame");
    },
    async updateUploadContentError({commit}, payload) {
        commit("setUploadContentError", payload);
    },
    async saveGame({commit}, type) {
        //Clear asset sources before saving JSON. Will retrieve the src by contentId when game is loaded back into wizard
        let selectedGameFormatted = JSON.parse(JSON.stringify(state.selectedGame));
        selectedGameFormatted.idle.asset.src = null;
        selectedGameFormatted.animation.startupAsset.src = null;
        selectedGameFormatted.animation.asset.src = null;
        selectedGameFormatted.background.asset.src = null;
        selectedGameFormatted.backgroundOverlay.asset.src = null;
        selectedGameFormatted.wheelOverlay.asset.src = null;
        selectedGameFormatted.foreground.asset.src = null;
        for (let i = 0; i < selectedGameFormatted.segments.length; i++) {
            selectedGameFormatted.segments[i].animation.src = null;
            selectedGameFormatted.segments[i].image.src = null;
        }
        for (let i = 0; i < selectedGameFormatted.segment_definitions.length; i++) {
            selectedGameFormatted.segment_definitions[i].animation.src = null;
            selectedGameFormatted.segment_definitions[i].image.src = null;
        }

        for (let i = 0; i < selectedGameFormatted.tiers.length; i++) {
            selectedGameFormatted.tiers[i].idle.asset.src = null;
            selectedGameFormatted.tiers[i].animation.asset.src = null;
            selectedGameFormatted.tiers[i].background.asset.src = null;
            selectedGameFormatted.tiers[i].backgroundOverlay.asset.src = null;
            selectedGameFormatted.tiers[i].wheelOverlay.asset.src = null;
            selectedGameFormatted.tiers[i].foreground.asset.src = null;
            for (let j = 0; j < selectedGameFormatted.tiers[i].segments.length; j++) {
                selectedGameFormatted.tiers[i].segments[j].animation.src = null;
                selectedGameFormatted.tiers[i].segments[j].image.src = null;
            }
            for (let j = 0; j < selectedGameFormatted.tiers[i].segment_definitions.length; j++) {
                selectedGameFormatted.tiers[i].segment_definitions[j].animation.src = null;
                selectedGameFormatted.tiers[i].segment_definitions[j].image.src = null;
            }
        }        

        const data = JSON.stringify(selectedGameFormatted);
        const jsonFile = new Blob([data], { type: 'application/json' });
        headers.tenantId = login.getters.getTenant();
        let noOfTiers = selectedGameFormatted.tiers == 0 ? 1 : selectedGameFormatted.tiers.length + 1;

        let formData = new FormData();
        formData.append("data", "data");
        formData.append("type", type + "_game"); 
        formData.append("prefix", headers.tenantId + "/" + noOfTiers + "_tier/" + selectedGameFormatted.no_of_segments + "_segment");
        formData.append("mimeType", "application/json");
        formData.append("fileName", state.selectedGame.name + "_" + type + ".json");
        formData.append("displayName", state.selectedGame.name);
        formData.append("tenantId", login.state.tenant);

        let id = state.selectedGame.savedGameId;
        if (state.selectedGame.status == 'Draft' && type == 'complete') { // If completing a draft game, give a null ID so a new ID is generated
            id = null;
        } else if (state.selectedGame.status == 'Complete' && type == 'draft') {  // If saving a Complete game as a draft, make a new draft game in the DB
            id = null;
            state.selectedGame.status = 'Draft';
            state.selectedGame.type = 'draft_game';
        } else if (type == 'draft') {
            state.selectedGame.status = 'Draft';
            state.selectedGame.type = 'draft_game';
        }
        formData.append("id", id); 
        formData.append("groupId", state.selectedGame.wheelGameId); 
        formData.append("file", jsonFile);

        await axios.post(`${i18n.t('app.content_service')}${i18n.t('app.content_controller')}/uploadContent`, formData, { headers: headers })
            .then(response => {
                commit("setSavedGameId", response.data.id);
                if (type == 'complete') {
                    state.selectedGame.status = 'Complete';
                }
            })
            .catch(error => {
                console.log(error);
                commit('setUploadContentError', error.message ? error.message : error.code);
            });
        
    },
    async clearSegmentOptions({ commit }) {
        commit("clearSegmentOptions");
    },
};

const mutations = {
    cloneGame: (state) => {
        const clone = Object.assign({}, state.selectedGame);
        clone.name = "";
        clone.wheelGameId = null;
        clone.savedGameId = null;
        clone.groupId = null;
        clone.status = null;
        clone.type = null;
        clone.dateCreated = null;
        state.selectedGame = clone;
    },
    setGame: (state, game) => {
        state.selectedGame = game;
        state.selectedGame.tierOptions = [];
        state.selectedGame.idleAnimationOptions = [];
        state.selectedGame.foregroundOptions = [];
        state.selectedGame.backgroundOptions = [];
        state.selectedGame.animationOptions = [];
        state.selectedGame.segmentOptions = [];
        state.selectedGame.winningAnimationsOptions = [];        
    },
    setWheelGameId: (state, gameId) => {
        state.selectedGame.wheelGameId = gameId;
    },
    setSavedGameId: (state, gameId) => {
        state.selectedGame.savedGameId = gameId;
    },
    setGameName: (state, gameName) => {
        state.selectedGame.name = gameName;
        for (let i = 0; i < state.selectedGame.tiers.length; i++) {
            state.selectedGame.tiers[i].name = state.selectedGame.name + "_tier_" + Number(i+2);
            state.selectedGame.tiers[i].nextRoundWheel = state.selectedGame.tiers.length > i + 1 ? state.selectedGame.name + "_tier_" + Number(i+3) : null;
            state.selectedGame.tiers[i].baseWheel = state.selectedGame.name;
        }
        state.selectedGame.nextRoundWheel = state.selectedGame.tiers[0] ? state.selectedGame.tiers[0].name : null;
    },
    setGameDescription: (state, gameDescription) => {
        state.selectedGame.description = gameDescription;
    },
    setIdleAnimationOptions: (state, idleAnimationOptions) => {
        state.selectedGame.idleAnimationOptions = idleAnimationOptions;
    },
    setForegroundOptions: (state, foregroundOptions) => {
        state.selectedGame.foregroundOptions = foregroundOptions;
    },
    setBackgroundOptions: (state, backgroundOptions) => {
        state.selectedGame.backgroundOptions = backgroundOptions;
    },
    setSelectedTier: (state, selectedTier) => {
        state.selectedGame.selectedTier = selectedTier;
    },
    setTierOptions: (state, tierOptions) => {
        state.selectedGame.tierOptions.push(tierOptions);
    },
    setSegmentOptions: (state, segmentOptions) => {
        state.selectedGame.segmentOptions = segmentOptions;
    },
    clearSegmentOptions: (state) => {
        state.selectedGame.segmentOptions = [];
    },
    setWinningAnimationsOptions: (state, winningAnimationsOptions) => {
        state.selectedGame.winningAnimationsOptions = winningAnimationsOptions;
    },
    clearSegmentOptions: (state) => {
        state.selectedGame.segmentOptions = [];
    },
    setIdleAnimation: (state, idleAnimation) => {
        if (idleAnimation == null) {
            state.selectedGame.idle.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
            return;
        }
        if (idleAnimation.contentId == null) {
            idleAnimation.contentId = idleAnimation.id;
        }
        state.selectedGame.idle.asset = Object.assign({}, idleAnimation);
    },
    setIdleAnimationTime: (state, idleAnimationTime) => {
        state.selectedGame.idle.delay = idleAnimationTime;
    },
    setIdleAnimationDuration: (state, idleAnimationDuration) => {
        state.selectedGame.idle.duration = idleAnimationDuration;
    },
    setStartupAnimation: (state, startupAnimation) => {
        if (startupAnimation == null) {
            state.selectedGame.animation.startupAsset = Object.assign({}, EMPTY_CONTENT_OBJECT);
            return;
        }
        if (startupAnimation.contentId == null) {
            startupAnimation.contentId = startupAnimation.id;
        }
        state.selectedGame.animation.startupAsset = Object.assign({}, startupAnimation);
    },
    setStartupAnimationTime: (state, startupAnimationTime) => {
        state.selectedGame.animation.startupAnimationDelay = startupAnimationTime;
    },
    setStartupAnimationDuration: (state, startupAnimationDuration) => {
        state.selectedGame.animation.startupAnimationDuration = startupAnimationDuration;
    },
    setStartupAnimationLoop: (state, flag) => {
        state.selectedGame.animation.startupAnimationLoop = flag;
    },

    setTiers: (state, tiers) => {
        state.selectedGame.tiers = []; // TODO - warn user of a clearing of details if changed after details entered
        tiers.forEach(tier => {
            state.selectedGame.tiers.push(Object.assign({}, tier));
        });
        state.selectedGame.nextRoundWheel = tiers[0] ? tiers[0].name : null;
    },
    setTierInfo: (state, tier) => {
        state.selectedGame.tiers[tier.index].name = tier.name;
        state.selectedGame.tiers[tier.index].nextRoundWheel = tier.nextRoundWheel;
        state.selectedGame.tiers[tier.index].baseWheel = tier.baseWheel;
        state.selectedGame.tiers[tier.index].background = Object.assign({}, tier.background);
        state.selectedGame.tiers[tier.index].backgroundOverlay = Object.assign({}, tier.backgroundOverlay);
        state.selectedGame.tiers[tier.index].wheelOverlay = Object.assign({}, tier.wheelOverlay);
        state.selectedGame.tiers[tier.index].foreground = Object.assign({}, tier.foreground);
        state.selectedGame.tiers[tier.index].idle = Object.assign({}, tier.idle);
        state.selectedGame.tiers[tier.index].animation = Object.assign({}, tier.animation);
        
        // TODO - segments and segment_definitions
    },
    setSegmentsForTier: (state, payload) => {
        if(payload.tier == 0) {
            state.selectedGame.segment_definitions = [
                {
                    id: 0,
                    name: "Segment 1", //Segment value
                    text: null,
                    bonusSegment: false,
                    image: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 1,
                    name: "Segment 2",
                    text: null,
                    bonusSegment: false,
                    image: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 2,
                    name: "Segment 3",
                    text: null,
                    bonusSegment: false,
                    image: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex(),
                }
            ];
            state.selectedGame.segments = payload.segments;
        } else {
            // -1 because base wheel is not in this array 
            state.selectedGame.tiers[payload.tier -1].segment_definitions= [
                {
                    id: 0,
                    name: "Segment 1",
                    text: null,
                    bonusSegment: false,
                    image: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 0,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 1,
                    name: "Segment 2",
                    text: null,
                    bonusSegment: false,
                    image: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 1,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex()
                },
                {
                    id: 2,
                    name: "Segment 3",
                    text: null,
                    bonusSegment: false,
                    image: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    animation: {
                        id: 2,
                        file: null,
                        base64: null,
                        contentId: null
                    },
                    fillStyle: randomPastelHex(),
                }
            ];
            state.selectedGame.tiers[payload.tier - 1 ].segments = payload.segments;
        }
    },
    resetBaseGameBonusSegments: (state) => {
        if (state.selectedGame.tiers.length == 0) {
            for (let i = 0; i < state.selectedGame.segment_definitions.length; i++) {
                state.selectedGame.segment_definitions[i].bonusSegment = false
            }
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                state.selectedGame.segments[i].bonusSegment = false
            }
        } else {
            let lastTier = state.selectedGame.tiers[state.selectedGame.tiers.length - 1];
            for (let i = 0; i < lastTier.segment_definitions.length; i++) {
                lastTier.segment_definitions[i].bonusSegment = false
            }
            for (let i = 0; i < lastTier.segments.length; i++) {
                lastTier.segments[i].bonusSegment = false
            }
        }
    },
    setSegmentChoice: (state, payload) => {
        if(payload.tier == 0) {
            state.selectedGame.no_of_segments = payload.noOfSegments;
        } else {
            state.selectedGame.tiers[payload.tier -1].no_of_segments = payload.noOfSegments;
        }
    },
    setImageContentSrc: (state, data) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i] && !data[i].mimeType.includes("image")) {
                continue;
            }
            let imageFileType = data[i].mimeType.split('/')[1];
            data[i].src = "data:image/" + imageFileType + ";base64," + data[i].data;
            if (data[i] && data[i].prefix.includes('segment')) {
                for (let x = 0; x < state.selectedGame.segment_definitions.length; x++) {
                    if (state.selectedGame.segment_definitions[x].image.src == null && data[i].displayName ==  state.selectedGame.segment_definitions[x].image.displayName) {
                        state.selectedGame.segment_definitions[x].image.src = data[i].src;
                    }
                }
                for (let x = 0; x < state.selectedGame.segments.length; x++) {
                    if (state.selectedGame.segments[x].image.contentId == data[i].id && state.selectedGame.segments[x].image.src == null) {
                        state.selectedGame.segments[x].image.src = data[i].src;
                    }
                }
            } else if (data[i] && data[i].prefix.includes('foreground') && state.selectedGame.foreground.asset.displayName == data[i].displayName
                 && data[i].id == state.selectedGame.foreground.asset.contentId) {
                state.selectedGame.foreground.asset.src = data[i].src;
            } else if (data[i] && data[i].prefix.includes('background') && state.selectedGame.background.asset.displayName == data[i].displayName
                 && data[i].id == state.selectedGame.background.asset.contentId) {
                state.selectedGame.background.asset.src = data[i].src;
            } else if (data[i] && data[i].prefix.includes('foreground') && data[i].id == state.selectedGame.wheelOverlay.asset.contentId) {
                state.selectedGame.wheelOverlay.asset.src = data[i].src;
            } else if (data[i] && data[i].prefix.includes('background') && state.selectedGame.backgroundOverlay.asset.displayName == data[i].displayName) {
                state.selectedGame.backgroundOverlay.asset.src = data[i].src;
            }

            if (state.selectedGame.tiers.length > 0) {
                for (let j = 0; j < state.selectedGame.tiers.length; j++) {
                    if (data[i] && data[i].prefix.includes('segment')) {
                        for (let x = 0; x < state.selectedGame.tiers[j].segment_definitions.length; x++) {
                            if (state.selectedGame.tiers[j].segment_definitions[x].image.src == null && data[i].displayName ==  state.selectedGame.tiers[j].segment_definitions[x].image.displayName) {
                                state.selectedGame.tiers[j].segment_definitions[x].image.src = data[i].src;
                            }
                        }
                        for (let x = 0; x < state.selectedGame.tiers[j].segments.length; x++) {
                            if (state.selectedGame.tiers[j].segments[x].image.contentId == data[i].id && state.selectedGame.tiers[j].segments[x].image.src == null) {
                                state.selectedGame.tiers[j].segments[x].image.src = data[i].src;
                            }
                        }
                    } else if (data[i] && data[i].prefix.includes('foreground') && state.selectedGame.tiers[j].foreground.asset.displayName == data[i].displayName
                            && data[i].id == state.selectedGame.tiers[j].foreground.asset.contentId) {
                        state.selectedGame.tiers[j].foreground.asset.src = data[i].src;
                    } else if (data[i] && data[i].prefix.includes('background') && state.selectedGame.tiers[j].background.asset.displayName == data[i].displayName
                            && data[i].id == state.selectedGame.tiers[j].background.asset.contentId) {
                        state.selectedGame.tiers[j].background.asset.src = data[i].src;
                    } else if (data[i] && data[i].prefix.includes('foreground') && data[i].id == state.selectedGame.tiers[j].wheelOverlay.asset.contentId) {
                        state.selectedGame.tiers[j].wheelOverlay.asset.src = data[i].src;
                    } else if (data[i] && data[i].prefix.includes('background') && state.selectedGame.tiers[j].backgroundOverlay.asset.displayName == data[i].displayName) {
                        state.selectedGame.tiers[j].backgroundOverlay.asset.src = data[i].src;
                    }
                }
            }
        }
    },
    fillNullSegments(state) {
        for (let i = 0; i < state.selectedGame.segments.length; i++) {
            if (state.selectedGame.segments[i].image.src == null) {
                state.selectedGame.segments[i].fillStyle = randomPastelHex();
            }
        }

        for (let i = 0; i < state.selectedGame.tiers.length; i++) {
            for (let j = 0; j < state.selectedGame.tiers[i].segments.length; j++) {
                if (state.selectedGame.tiers[i].segments[j].image.src == null) {
                    state.selectedGame.tiers[i].segments[j].fillStyle = randomPastelHex();
                }
            }
        }
    },
    setSegmentDefinition(state, seg_defs) {
        state.selectedGame.segment_definitions = seg_defs;
    },
    addSegmentDefinition(state, payload) {
        var newSegment = Object.assign({}, EMPTY_SEGMENT_OBJECT);
        if(payload.tier == 0) {
            newSegment.id = state.selectedGame.segment_definitions.length +1;
            newSegment.name = "Segment " + newSegment.id;
            state.selectedGame.segment_definitions.push(Object.assign({}, newSegment));
        } else {
            newSegment.id = state.selectedGame.tiers[payload.tier -1].segment_definitions.length +1;
            newSegment.name = "Segment " + newSegment.id;
            state.selectedGame.tiers[payload.tier -1].segment_definitions.push(Object.assign({}, newSegment));
        }
    },
    setSegmentDefinitionName(state, payload) {
        let oldName ='';
        if(payload.tier == 0) {
            oldName = state.selectedGame.segment_definitions[payload.index].name;
            state.selectedGame.segment_definitions[payload.index].name = payload.name;
        } else {
            oldName = state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].name;
            state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].name = payload.name;
        }

        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (oldName == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].name = state.selectedGame.segment_definitions[payload.index].name;
                    state.selectedGame.segments[i].value = state.selectedGame.segment_definitions[payload.index].name;
                    state.selectedGame.segments[i].definitionName = state.selectedGame.segment_definitions[payload.index].name;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (oldName == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].name = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name;
                    state.selectedGame.tiers[payload.tier - 1].segments[i].value = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name;
                    state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name;
                }
            }
        }
    },
    setSegmentDefinitionText(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.segment_definitions[payload.index].text = payload.text;
        } else {
            state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].text = payload.text;
        }

        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].text = state.selectedGame.segment_definitions[payload.index].text;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].text = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].text;
                }
            }
        }
    },
    setSegmentDefinitionBonus(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.segment_definitions[payload.index].bonusSegment = payload.bonusSegment;
        } else {
            state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].bonusSegment = payload.bonusSegment;
        }

        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].bonusSegment = state.selectedGame.segment_definitions[payload.index].bonusSegment;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1]
                    .segments[i].bonusSegment = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].bonusSegment;
                }
            }
        }
    },
    removeSegmentDefinition(state, payload) {
        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segments[i].image && state.selectedGame.segments[i].image.contentId 
                        == state.selectedGame.segment_definitions[payload.index].image.contentId && 
                        state.selectedGame.segments[i].definitionName == state.selectedGame.segment_definitions[payload.index].name) {
                    const id = state.selectedGame.segments[i].id;
                    state.selectedGame.segments[i] = Object.assign({}, EMPTY_SEGMENT_OBJECT);
                    state.selectedGame.segments[i].id = id;
                }
            }
            state.selectedGame.segment_definitions.splice(payload.index, 1);
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segments[i].image && state.selectedGame.tiers[payload.tier - 1].segments[i].image.contentId 
                        == state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].image.contentId && 
                        state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName == state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name) {
                    const id = state.selectedGame.tiers[payload.tier - 1].segments[i].id;
                    state.selectedGame.tiers[payload.tier - 1].segments[i] = Object.assign({}, EMPTY_SEGMENT_OBJECT);
                    state.selectedGame.tiers[payload.tier - 1].segments[i].id = id;
                }
            }
            state.selectedGame.tiers[payload.tier - 1].segment_definitions.splice(payload.index, 1);
        }        
    },
    setForegroundContent(state, payload){
        
        if(payload.tier == 0) {
            if (payload.foregroundContent == null) {
                state.selectedGame.foreground.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.foregroundContent.contentId == null) {
                payload.foregroundContent.contentId = payload.foregroundContent.id;
            }
            state.selectedGame.foreground.asset = Object.assign({}, payload.foregroundContent);
        } else {
            if (payload.foregroundContent == null) {
                state.selectedGame.tiers[payload.tier -1].foreground.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.foregroundContent.contentId == null) {
                payload.foregroundContent.contentId = payload.foregroundContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].foreground.asset = Object.assign({}, payload.foregroundContent);
        }
    },
    setForegroundContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.foreground.assetSize = payload.assetSize
        } else {
            state.selectedGame.tiers[payload.tier -1].foreground.assetSize = payload.assetSize
        }
    },
    setBackgroundContent(state, payload){
        if(payload.tier == 0) {
            if (payload.backgroundContent == null) {
                state.selectedGame.background.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundContent.contentId == null) {
                payload.backgroundContent.contentId = payload.backgroundContent.id;
            }
            state.selectedGame.background.asset = Object.assign({}, payload.backgroundContent);
        } else {
            if (payload.backgroundContent == null) {
                state.selectedGame.tiers[payload.tier -1].background.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundContent.contentId == null) {
                payload.backgroundContent.contentId = payload.backgroundContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].background.asset = Object.assign({}, payload.backgroundContent);
        }
    },
    setBackgroundContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.background.assetSize = payload.assetSize;
        } else {
            state.selectedGame.tiers[payload.tier -1].background.assetSize = payload.assetSize;
        }
    },
    setWheelOverlayContent(state, payload){
        
        if(payload.tier == 0) {
            if (payload.wheelOverlayContent == null) {
                state.selectedGame.wheelOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.wheelOverlayContent.contentId == null) {
                payload.wheelOverlayContent.contentId = payload.wheelOverlayContent.id;
            }
            state.selectedGame.wheelOverlay.asset = Object.assign({}, payload.wheelOverlayContent);
        } else {
            if (payload.wheelOverlayContent == null) {
                state.selectedGame.tiers[payload.tier -1].wheelOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.wheelOverlayContent.contentId == null) {
                payload.wheelOverlayContent.contentId = payload.wheelOverlayContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].wheelOverlay.asset = Object.assign({}, payload.wheelOverlayContent);
        }
    },
    setWheelOverlayContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.wheelOverlay.assetSize = payload.assetSize
        } else {
            state.selectedGame.tiers[payload.tier -1].wheelOverlay.assetSize = payload.assetSize
        }
    },
    setBackgroundOverlayContent(state, payload){
        if(payload.tier == 0) {
            if (payload.backgroundOverlayContent == null) {
                state.selectedGame.backgroundOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundOverlayContent.contentId == null) {
                payload.backgroundOverlayContent.contentId = payload.backgroundOverlayContent.id;
            }
            state.selectedGame.backgroundOverlay.asset = Object.assign({}, payload.backgroundOverlayContent);
        } else {
            if (payload.backgroundOverlayContent == null) {
                state.selectedGame.tiers[payload.tier -1].backgroundOverlay.asset = Object.assign({}, EMPTY_CONTENT_OBJECT);
                return;
            }
            if (payload.backgroundOverlayContent.contentId == null) {
                payload.backgroundOverlayContent.contentId = payload.backgroundOverlayContent.id;
            }
            state.selectedGame.tiers[payload.tier -1].backgroundOverlay.asset = Object.assign({}, payload.backgroundOverlayContent);
        }
    },
    setBackgroundOverlayContentSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.backgroundOverlay.assetSize = payload.assetSize;
        } else {
            state.selectedGame.tiers[payload.tier -1].backgroundOverlay.assetSize = payload.assetSize;
        }
    },
    setWinningAnimationSize(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.animationSize = payload.assetSize;
        } else {
            state.selectedGame.tiers[payload.tier -1].animationSize = payload.assetSize;
        }
    },
    setWinningAnimationDuration(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.animation.winningAnimationDuration = payload.winningAnimationDuration;
        } else {
            state.selectedGame.tiers[payload.tier -1].animation.winningAnimationDuration = payload.winningAnimationDuration;
        }
    },
    setWinningAnimationDelay(state, payload){
        if(payload.tier == 0) {
            state.selectedGame.animation.winningAnimationDelay = payload.winningAnimationDelay;
        } else {
            state.selectedGame.tiers[payload.tier -1].animation.winningAnimationDelay = payload.winningAnimationDelay;
        }
    },
    setForegroundSrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.foreground.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].foreground.asset.src = payload.src;
        }
    },
    setBackgroundSrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.background.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].background.asset.src = payload.src;
        }
    },
    setWheelOverlaySrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.wheelOverlay.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].wheelOverlay.asset.src = payload.src;
        }
    },
    setBackgroundOverlaySrc(state, payload) {
        if(payload.tier == 0) {
            state.selectedGame.backgroundOverlay.asset.src = payload.src;
        } else {
            state.selectedGame.tiers[payload.tier - 1].backgroundOverlay.asset.src = payload.src;
        }
    },
    setIdleAnimationSrc(state, payload) {
        state.selectedGame.idle.asset.src = payload;
    },
    setStartupAnimationSrc(state, payload) {
        state.selectedGame.animation.startupAsset.src = payload;
    },
    setSegmentAnimation(state, payload) {
        if(payload.tier == 0) {
            if (payload.animation == null) {
                state.selectedGame.segment_definitions[payload.index].animation = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.segment_definitions[payload.index].animation = Object.assign({}, payload.animation);
                state.selectedGame.segment_definitions[payload.index].animation.contentId = payload.animation.contentId ?
                    payload.animation.contentId : state.selectedGame.segment_definitions[payload.index].animation.id;
                state.selectedGame.segment_definitions[payload.index].animation.id = payload.index;
            }
        } else {
            if (payload.animation == null) {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation = Object.assign({}, payload.animation);
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation.contentId = payload.animation.contentId ?
                payload.animation.contentId : state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation.id;
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].animation.id = payload.index;
            }
        }

        // If other segments share same animation, copy the src across to them
        if (payload.animation != null) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segments[i].animation && state.selectedGame.segments[i].animation.contentId == payload.animation.contentId && state.selectedGame.segments[i].animation.src == null) {
                    state.selectedGame.segments[i].animation.src = payload.animation.src;
                }
            }
            if (state.selectedGame.tiers.length > 0) {
                for (let j = 0; j < state.selectedGame.tiers.length; j++) {
                    for (let i = 0; i < state.selectedGame.tiers[j].segments.length; i++) {
                        if (state.selectedGame.tiers[j].segments[i].animation && state.selectedGame.tiers[j].segments[i].animation.contentId == payload.animation.contentId && state.selectedGame.tiers[j].segments[i].animation.src == null) {
                            state.selectedGame.tiers[j].segments[i].animation.src = payload.animation.src;
                        }
                    }
                }
            }
        }
        
        //Update all existing wheel segments with new segment definition
        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].animation = state.selectedGame.segment_definitions[payload.index].animation;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].animation = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].animation;
                }
            }
        }
    },
    setSegmentImage(state, payload) {
        if(payload.tier == 0) {
            if (payload.image == null) {
                state.selectedGame.segment_definitions[payload.index].image = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.segment_definitions[payload.index].image = Object.assign({}, payload.image);
                state.selectedGame.segment_definitions[payload.index].image.contentId = payload.image.contentId ?
                    payload.image.contentId : state.selectedGame.segment_definitions[payload.index].image.id;  //contentId is returned as 'id' from the DB
                state.selectedGame.segment_definitions[payload.index].image.id = payload.index;
                state.selectedGame.segment_definitions[payload.index].fillStyle = null;
            }
        }
        else {
            if (payload.image == null) {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image = Object.assign({}, EMPTY_CONTENT_OBJECT);
            } else {
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image = Object.assign({}, payload.image);
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image.contentId = payload.image.contentId ?
                payload.image.contentId : state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image.id;
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].image.id = payload.index;
                state.selectedGame.tiers[payload.tier -1].segment_definitions[payload.index].fillStyle = null;
            }
        }

        //Update all existing wheel segments with new segment definition
        if(payload.tier == 0) {
            for (let i = 0; i < state.selectedGame.segments.length; i++) {
                if (state.selectedGame.segment_definitions[payload.index].name == state.selectedGame.segments[i].definitionName) {
                    state.selectedGame.segments[i].image = state.selectedGame.segment_definitions[payload.index].image;
                }
            }
        } else {
            for (let i = 0; i < state.selectedGame.tiers[payload.tier - 1].segments.length; i++) {
                if (state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].name == state.selectedGame.tiers[payload.tier - 1].segments[i].definitionName) {
                    state.selectedGame.tiers[payload.tier - 1].segments[i].image = state.selectedGame.tiers[payload.tier - 1].segment_definitions[payload.index].image;
                }
            }
        }
    },
    setGameJSON: (state, gameJSON) => {
        state.gameJSON = gameJSON;
    },
    addNewGame: (state, game) => {
        state.gamesList.push(game)
    },
    removeGame: (state, id) => {
        state.gamesList.splice(game => game.id, 1);
    },
    addNewAsset: (state, asset) => {
        state.assetList.push(asset)
    },
    addNewSharedAsset: (state, asset) => {
        state.sharedAssetList.push(asset)
    },
    removeAsset: (state, id) => {
        state.assetList.splice(asset => asset.id == id, 1);
    },
    clearAssetList: (state) => {
        state.assetList = [];
    },
    formatAssetList: (state, assetList) => {
        state.assetList = assetList;
        if (!state.assetList || state.assetList.length == 0) return;

        let uniqueAssets = Array.from(state.assetList.reduce((acc, current) => {
            acc.set(current.id, current);
            return acc;
        }, new Map()).values());
        
        for (let i = 0; i < uniqueAssets.length; i++) {
            let dateObj = new Date(uniqueAssets[i].dateCreated);
            let dateCreated = dateObj.getDate() + '/' + (dateObj.getMonth() + 1) + '/' + dateObj.getFullYear().toString().substr(2, 2);
            uniqueAssets[i].dateCreated = dateCreated;

            if (uniqueAssets[i].prefix.includes('/idle')) {
                uniqueAssets[i].assetType = 'Idle Animation';
            } else if (uniqueAssets[i].prefix.includes('/foreground')) {
                uniqueAssets[i].assetType = 'Foreground';
            } else if (uniqueAssets[i].prefix.includes('/background')) {
                uniqueAssets[i].assetType = 'Background';
            } else if (uniqueAssets[i].prefix.includes('/winning-animation')) {
                uniqueAssets[i].assetType = 'Winning Animation';
            } else if (uniqueAssets[i].prefix.includes('/segment/54')) {
                uniqueAssets[i].assetType = '54 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/27')) {
                uniqueAssets[i].assetType = '27 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/18')) {
                uniqueAssets[i].assetType = '18 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/9')) {
                uniqueAssets[i].assetType = '9 Segment';
            } else if (uniqueAssets[i].prefix.includes('/segment/3')) {
                uniqueAssets[i].assetType = '3 Segment';
            } else {
                uniqueAssets[i].id = null;
            }
        }
        uniqueAssets = uniqueAssets.filter(function (asset) {
            return asset.id != null;
        });

        state.assetList = uniqueAssets;
    },
    setStage: (state, stage) => {
        state.currentStage = stage;
    },
    setGameSegment: (state, newSegmentInfo) => {
        let segment = {
            id: newSegmentInfo.segmentId,
            value: newSegmentInfo.segment.name,
            text: newSegmentInfo.segment.text,
            bonusSegment: newSegmentInfo.segment.bonusSegment,
            image: newSegmentInfo.segment.image,
            animation: newSegmentInfo.segment.animation,
            definitionName: newSegmentInfo.segment.name,
            fillStyle: newSegmentInfo.segment.fillStyle,
            textFillStyle: "black",
        }
        if (newSegmentInfo.tier == 0) {
            state.selectedGame.segments[newSegmentInfo.segmentId] = segment;
        } else {
            state.selectedGame.tiers[newSegmentInfo.tier - 1].segments[newSegmentInfo.segmentId] = segment;
        }
    },
    setUploadContentError: (state, error) => {
        state.uploadContentError = error;
    },
    setDropdownsLoaded: (state, flag) => {
        state.dropdownsLoaded = flag;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    i18n
}