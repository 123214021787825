export default {
    app: {
        name: "Configurator",
        version: "1.0",
        content_service: "https://server.digiwheelwizard.com/",
        //Attempted
        // https://server.digiwheelwizard.com/content-service
        // https://server.digiwheelwizard.com/

        // prod -> content_service: "https://server.digiwheelwizard.com/",
        // test -> content_service: "http://ec2-52-16-202-96.eu-west-1.compute.amazonaws.com:8083/"
        // local -> content_service: "http://localhost:8083/",
        content_controller: "content",
        authentication_controller: "auth"
    },
    action: {
        continue: "Continue",
        submit: "Submit",
        cancel: "Cancel",
        goback: "Go Back",
        search: "Search",
        draft: "Save as draft",
        save: "Update game",
        delete: "Delete",
        randomspin: "Random Spin",
        generateGame: "Generate Game",
        confirmGame: "Confirm Game",
        rotateLeft: "Rotate Left",
        rotateRight: "Rotate Right",
        add: "Add",
        upload: "Upload"
    },
    login: {
        title: "Sign in with your Digiwheel account",
        username: "Enter your username",
        password: "Enter your password",
        enter: "Sign in",
        reset: "Trouble signing in?",
        error: "Incorrect password. Please try again."
    },
    dashboard: {
        title: "Custom Wheels",
        subtitle: "View, duplicate, edit or delete existing wheels. Or create a new wheel game."
    },
    assets: {
        title: "Asset Library",
        subtitle: "View, update or delete your assets."
    },
    create: {
        assign_value: "Assign value",
        details: "Game Details",
        segments: "Define Segments",
        selected_segment: "Selected segment",
        wheel: "Create Wheel",
        level: "Additional Level",
        verify: "Verify"
    },
    details: {
        wheel_name: "Wheel Name",
        wheel_name_placeholder: "Give your wheel a name",
        wheel_description: "Wheel Description",
        wheel_description_placeholder: "Add a wheel description",
        wheel_tiers: "Number of Tiers",
        name_required:"Game Name is required",
        idle_animation: "Idle Animation",
        idle_animation_timer: "Idle Animation Timer",
        idle_animation_duration: "Idle Animation Duration",
        startup_animation: "Start-up Animation",
        startup_animation_timer: "Start-up Animation Timer",
        startup_animation_duration: "Start-up Animation Duration",
        startup_animation_loop: "Loop Animation",
        segment_options: "Number of Segments",
        make_selection: "Make a selection",
        selection_required: "Selection is required",
        upload_files_title: "Upload files",
        upload_files_subtitle: "Start-up animation, idle animation, centre frame",
        upload_files_placeholder: "Drag and drop, or browse your files",
        upload_animation: "Upload Animation",
        upload_idle_subtitle: "Video to appear when wheel is inactive(webm and mp4)",
        upload_idle_timer_subtitle: "Duration before Idle Animation is displayed (in seconds)",
        upload_idle_duration_subtitle: "Duration that Idle Animation is displayed for (in seconds)",
        upload_startup_subtitle: "Animation played before the wheel is loaded(webm and mp4)",
        upload_startup_timer_subtitle: "Duration before Start-up Animation is displayed (in seconds)",
        upload_startup_duration_subtitle: "Duration that Start-up Animation is displayed for (in seconds)",
        upload_foreground_content: "Upload Foreground Content",
        upload_foreground_content_subtitle: "Video or Image that will be available for foreground and wheel overlay selection",
        upload_background_content: "Upload Background Content",
        upload_background_content_subtitle: "Video or Image that will be available for background and background overlay selection",
        select_foreground_content: "Foreground Content",
        select_foreground_content_subtitle: "Displayed in front of the wheel overlay and behind the animation layer. Primarily used to display a logo on the wheel.",
        select_foreground_content_size: "Foreground Size",
        select_foreground_content_size_subtitle: "Size as a percentage of the wheel screen",
        select_background_content: "Background Content",
        select_background_content_subtitle: "Sits behind all other content. It will remain upright even as the wheel spins.",
        select_background_content_size: "Background Size",
        select_wheelOverlay_content: "Wheel Overlay Content",
        select_wheelOverlay_content_subtitle: "Displayed in front of the wheel segments and behind the foreground. Primarily used to add visual flourish to a wheel.",
        select_wheelOverlay_content_size: "Wheel Overlay Size",
        select_wheelOverlay_content_size_subtitle: "Size as a percentage of the wheel screen",
        select_backgroundOverlay_content: "Background Overlay Content",
        select_backgroundOverlay_content_subtitle: "Displayed behind the wheel segments and in front of the background. Primarily used to frame the background.",
        select_backgroundOverlay_content_size: "Background Overlay Size",
        upload_segment_content: "Upload Segment Images",
        upload_segment_subtitle: "Individual segment images",
        upload_segment_animations: "Upload Winning Animations",
        upload_segment_animations_subtitle: "Winning animations for segments",
        segment_animation_size: "Winning Animation Size",
        segment_animation_size_subtitle: "Size as a percentage of the wheel screen",
        segment_animation_duration: "Winning Animation Duration",
        segment_animation_duration_subtitle: "Duration that segment animation is displayed for (in seconds)",
        segment_animation_delay: "Winning Animation Delay",
        segment_animation_delay_subtitle: "Delay before that segment animation is displayed (in seconds)",
        choose_file: "Choose file",
        characters_allowed: "Game name may only include alphanumeric characters, spaces, underscores, and hyphens",
        character_length: "Game name must be less than 20 characters",
        wheelName: "Wheel Name",
        numberOfTiers: "Number of Tiers",
        tierOneSegmentCount: "Tier 1 Segment Count",
        bonusSegmentCount: "Bonus Segment Count",
        selectTier: "Select Tier",
        baseWheel: "Base Wheel",
        addNew: "Add New",
        addTag: "Add Tag",
        confirmDelete: "Are you sure you want to delete? This will also delete any associated assets.",
        newFilter: "New Filter",
        newAsset: "New Asset",
        segment_value_subtitle: "In case there is a Kiosk CMS being used for this game, make sure to use numeric value to specify cash, numerical value followed by P to specify Points and numeric value followed by Pr to signal that it is a Promotional Value."
    },
    segment: {
        header: "Segment {index}",
        name: "Segment Name",
        number_of_segments: "Number of segments",
        name_required:"Name is required",
        name_length_error: 'Segment name must be less than 20 characters',
        upload_files_title: "Upload files",
        upload_files_subtitle: "Tier 1 segments, animations, centre content",
        upload_files_placeholder: "Drag and drop, or browse your files",
    }
};    
  