import { render, staticRenderFns } from "./DigiWheel.vue?vue&type=template&id=0dcfa899&scoped=true&"
import script from "./DigiWheel.vue?vue&type=script&lang=js&"
export * from "./DigiWheel.vue?vue&type=script&lang=js&"
import style0 from "./DigiWheel.vue?vue&type=style&index=0&id=0dcfa899&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dcfa899",
  null
  
)

export default component.exports